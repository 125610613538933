import { format } from "date-fns";
import _, { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Button, Form, InputGroup, Stack, Table } from "react-bootstrap";
import {
  ServiceRequestModel,
  searchDevices,
  useDeleteServiceRequestMutation,
  useSearchServiceRequestMutation,
} from "../../redux/apis/serviceRequestApi";
import { useAuth } from "../../redux/hooks/useAuth";
import TitleInfo from "../common/TitleInfo";
import AddServiceRequest from "./add-service-request";
import UpdateStatus from "./update-status-form";

function ServiceRequestList() {
  const user = useAuth();
  const [showNewServiceRequestForm, setShowNewServiceRequestForm] =
    useState(false);
  const [selectedServiceRequest, setServiceRequest] = useState(null);
  const [deleteFn] = useDeleteServiceRequestMutation();
  const [searchResult] = useSearchServiceRequestMutation();
  const handleClickEdit = (item: any) => {
    setShowNewServiceRequestForm(true);
    setServiceRequest(item);
  };
  const [serviceRequestList, setServiceRequestList] =
    useState<ServiceRequestModel[]>();
  const [showUpdateStatus, setUpdateStatus] = useState(false);

  const handleSearch = async (event: any) => {
    searchMachine(event?.target?.value);
  };

  const debouncedResults = useMemo(() => {
    return _.debounce(handleSearch, 300);
  }, []);

  useEffect(() => {
    const delayedSearch = debounce(async () => {
      // Perform the search operation
      await searchMachine("");
    }, 300); // Adjust delay as needed

    delayedSearch();

    // Cleanup function to cancel the debounce timer
    return delayedSearch.cancel;
    return debouncedResults.cancel;
  }, []);

  const onDelete = async (id: number) => {
    try {
      if (window.confirm("Are you sure you want to delete this item?")) {
        const result = await deleteFn(id).unwrap();
        handleSearch("");
      }
    } catch {}
  };

  const handleClose = () => {
    setShowNewServiceRequestForm(false);
    setUpdateStatus(false);
  };

  const renderHeader = () => {
    return (
      <thead>
        <tr>
          <th>Ticket No.</th>
          <th>Date</th>
          <th>Order Id</th>
          <th>Machine Id</th>
          <th>Requested By</th>
          <th>Phone Number</th>
          <th>Service Type</th>
          <th>Warranty Expire Date</th>
          <th>Status</th>
          <th>Resolved Date</th>
          <th>Description</th>
          <th style={{ width: "150px" }}>Actions</th>
        </tr>
      </thead>
    );
  };

  const renderTableDataRow = (item: any, index: number) => {
    const uniqueKey = `user-${item.id}-${index}`;
    return (
      <tr key={uniqueKey}>
        <td>{item.ticketId}</td>
        <td>
          {item.requestedDate && format(item.requestedDate, "dd/MM/yyyy")}
        </td>
        <td>{item.orderDetail?.orderId}</td>
        <td>{item.deviceDetail?.deviceId}</td>
        <td>
          {item.userProfile?.firstName + " " + item.userProfile?.lastName}
        </td>
        <td>{item.userProfile?.phoneNumber}</td>
        <td>{item.serviceType}</td>
        <td>
          {item.orderDetail?.warrantyExpireDate &&
            format(item.orderDetail.warrantyExpireDate, "dd/MM/yyyy")}
        </td>
        <td>{item.status}</td>
        <td>{item.resolvedDate && format(item.resolvedDate, "dd/MM/yyyy")}</td>
        <td>{item.serviceDescription}</td>
        <td>
          <div className="d-flex">
            <button
              title="Update Status"
              className="btn btn-link"
              onClick={() => {
                setUpdateStatus(true);
                setServiceRequest(item);
              }}
            >
              <i className="bi bi-card-checklist"></i>
            </button>
            <button
              className="btn btn-link"
              onClick={() => handleClickEdit(item)}
            >
              <i className="bi bi-pencil-square"></i>
            </button>
            <button className="btn btn-link" onClick={() => onDelete(item.id)}>
              <i className="bi bi-trash3-fill text-danger"></i>
            </button>
          </div>
        </td>
      </tr>
    );
  };

  const renderTableRows = () => {
    return serviceRequestList?.map((item, index) => {
      return renderTableDataRow(item, index);
    });
  };

  const searchMachine = async (searchText: string) => {
    const device = {
      searchText: searchText,
      role: "",
      currentUserId: user?.account?.user?.id!,
      id: 0,
    } as searchDevices;
    const result = await searchResult(device).unwrap();
    setServiceRequestList(result);
  };

  return (
    <>
      <TitleInfo
        title={"Service Request"}
        subTitle={"Here is the list of all requests"}
      />
      <Stack
        direction="horizontal"
        gap={3}
        className="justify-content-end mb-2"
      >
        <div className="flex-fill"></div>
        <InputGroup size="sm" className="w-50">
          <InputGroup.Text id="search-addon1">
            <i className="bi bi-search"></i>
          </InputGroup.Text>
          <Form.Control
            placeholder="Search Order Id, Customer Name or Email"
            onChange={debouncedResults}
          />
        </InputGroup>
        <div className="vr" />
        <Button
          className="btn-sm"
          onClick={() => {
            setServiceRequest(null);
            setShowNewServiceRequestForm(true);
          }}
        >
          Add Request
        </Button>
      </Stack>
      {showUpdateStatus && (
        <UpdateStatus
          handleClose={handleClose}
          serviceRequest={selectedServiceRequest}
          isOpenPopup={showUpdateStatus}
          refreshTableData={handleSearch}
        />
      )}
      {showNewServiceRequestForm && (
        <AddServiceRequest
          handleClose={handleClose}
          device={selectedServiceRequest}
          refreshTableData={handleSearch}
        />
      )}
      <Table responsive hover size="sm" className="custom-table">
        {renderHeader()}
        <tbody>{renderTableRows()}</tbody>
      </Table>
    </>
  );
}

export default ServiceRequestList;
