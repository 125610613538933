import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { Badge, Button, Stack } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  useDeleteDeviceMutation,
  useGetAllDevicesQuery,
} from "../../redux/apis/deviceApi";
import { uploadDeviceReadings } from "../../redux/apis/deviceReadingsApi";
import { useAuth } from "../../redux/hooks/useAuth";
import { useLov } from "../../redux/hooks/useLov";
import { addToast } from "../../redux/slices/utilitySlice";
import CustomDropdown from "../common/custom-dropdown";
import TitleInfo from "../common/TitleInfo";
import AddDevice from "./add-device";
import DeviceSettings from "./manage-device-settings";
import AddDeviceReading from './device-reading';

function MachineList() {
  const user = useAuth();
  const { filteredLovs } = useLov("DeviceStatus");

  // Access the 'route params' query parameter
  const [routeParams] = useSearchParams();
  const roureQuery = routeParams.get("status");

  const [showNewDeviceForm, setShowNewDeviceForm] = useState(false);
  const [showEditDeviceForm, setShowEditDeviceForm] = useState(false);
  const [showAddDeviceReading, setShowAddDeviceReading] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [showDeviceSettingForm, setShowDeviceSettingForm] = useState(false);

  const [searchText, setSearchTerm] = useState(roureQuery || "All");

  const { data } = useGetAllDevicesQuery(user.account.user?.id || 0);
  const [machineList, setMachineList] = useState(data || []);
  const [deleteFn] = useDeleteDeviceMutation();
  const inputExportFileRef = useRef<HTMLInputElement>(null);
  const sampleFilePath = require("../../images/aeronero.xlsx") as any;
  const dispatch = useDispatch();

  useEffect(() => {
    const filteredMachines =
      searchText === "All" ? data : _.filter(data, ["statusCode", searchText]);
    setMachineList(filteredMachines || []);
  }, [searchText, data]);

  const handleClickEdit = (item: any) => {
    setShowEditDeviceForm(true);
    setSelectedDevice(item);
  };

  const onDelete = async (id: number) => {
    try {
      if (window.confirm("Are you sure you want to delete this item?")) {
        const result = await deleteFn(id).unwrap();
      }
    } catch { }
  };

  const renderHeader = () => {
    return (
      <thead>
        <tr>
          <th>Machine ID</th>
          <th>Name</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
    );
  };

  const renderTableDataRow = (item: any, index: number) => {
    const uniqueKey = `user-${item.id}-${index}`;
    const statusClass = ["SOLD", "AVAILABLE"].includes(item.statusCode)
      ? item.statusCode === "AVAILABLE"
        ? "success"
        : "danger"
      : "";

    return (
      <tr key={uniqueKey}>
        <td>{item.deviceId}</td>
        <td>{item.deviceName}</td>
        <td>
          <h6 className="m-0">
            <Badge bg={statusClass}>{item.status}</Badge>
          </h6>
        </td>
        <td>
          <button
            title="Manage Settings"
            className="btn btn-link"
            onClick={() => {
              setShowDeviceSettingForm(true);
              setSelectedDevice(item as any);
            }}
          >
            <i className="bi bi-gear"></i>
          </button>
          <button
            className="btn btn-link py-0 px-1"
            onClick={() => handleClickEdit(item)}
          >
            <i className="bi bi-pencil-square"></i>
          </button>
          <button className="btn btn-link" onClick={() => onDelete(item.id)}>
            <i className="bi bi-trash3-fill text-danger"></i>
          </button>
        </td>
      </tr>
    );
  };

  const renderTableRows = () => {
    return machineList?.map((item, index) => {
      return renderTableDataRow(item, index);
    });
  };

  const handleClose = () => {
    selectedDevice !== null
      ? setShowEditDeviceForm(false)
      : setShowNewDeviceForm(false);
    setSelectedDevice(null);
    setShowDeviceSettingForm(false);
  };

  const handleAddDeviceReadingClose = () => {
    setShowAddDeviceReading(false);
  }

  const handleAddDeviceReadingOpen = () => {
    setShowAddDeviceReading(true);
  }

  const handleFileChange = async (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (!!file) {
      const response = await uploadDeviceReadings(file);

      let msg: any = {
        messageType: !!response.ok ? "success" : "danger",
        bodyText: !!response.ok
          ? "Machine readings uploaded successfully."
          : (!!response?.errorList && !!response?.errorList.length
            ? response?.errorList.map((err: any) => err[Object.keys(err)[0]]).join(", ")
            : response.errors[0]),
      };

      if (!response.ok && !!response?.errorList) {
        msg.headerText = response.errors[0];
      }

      // Reset the file input value
      if (inputExportFileRef.current) {
        inputExportFileRef.current.value = "";
      }

      dispatch(addToast(msg));
    }
  };

  const handleButtonClick = () => {
    // Trigger the input file click
    inputExportFileRef?.current?.click();
  };

  return (
    <>
      <TitleInfo title={"Machine"} />
      <Stack direction="horizontal" gap={3} className="mb-2">
        <div className="flex-fill"></div>
        <CustomDropdown
          title={searchText}
          options={["All", ...filteredLovs.map((d) => d.lovCode)]}
          onSelect={(val) => {
            setSearchTerm(val);
          }}
        />
        <div className="vr" />
        <input
          className="d-none"
          type="file"
          accept=".xlsx, .xls, .csv"
          ref={inputExportFileRef}
          onChange={handleFileChange}
        />
        <Button
          className="btn-sm btn-success"
          onClick={handleButtonClick}
          data-toggle="tooltip"
          data-placement="top"
          title="Upload for machine data."
        >
          <i className="bi bi-cloud-upload"></i> Upload
        </Button>
        <a
          href={sampleFilePath}
          download="aeronero-sample"
          target="_blank"
          rel="noreferrer"
        >
          <Button
            className="btn-sm btn-success"
            data-toggle="tooltip"
            data-placement="top"
            title="Sample upload file format."
          >
            <i className="bi bi-download"></i> Upload Format
          </Button>
        </a>
        <div className="vr" />
        <Button className="btn-sm" onClick={() => setShowNewDeviceForm(true)}>
          New Machine
        </Button>
        {data?.length && (
          <Button className="btn-sm" onClick={handleAddDeviceReadingOpen}>
            Add Machine Reading
          </Button>
        )}
      </Stack>
      {showDeviceSettingForm && (
        <DeviceSettings
          handleClose={handleClose}
          device={selectedDevice}
          isOpenPopup={showDeviceSettingForm}
        />
      )}
      {(showNewDeviceForm || showEditDeviceForm) && (
        <AddDevice
          handleClose={handleClose}
          device={selectedDevice}
        />
      )}
      {showAddDeviceReading && data?.length && (
        <AddDeviceReading
          handleClose={handleAddDeviceReadingClose}
          devices={data}
        />
      )}
      <Table responsive hover size="sm" className="custom-table">
        {renderHeader()}
        <tbody>{renderTableRows()}</tbody>
      </Table>
    </>
  );
}

export default MachineList;
