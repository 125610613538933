import { useState } from "react";
import { Button, Dropdown, Form, InputGroup, Modal } from "react-bootstrap";
import {
  useCreateUserProfileMutation,
  useUpdateUserProfileMutation
} from "../../redux/apis/userProfileApi";
import { useAuth } from "../../redux/hooks/useAuth";
import { COUNTRYCODES, ROLES } from "../common/helper/helper";

function AddUser(props: {
  handleClose: (result: any) => void;
  isOpenPopup: boolean;
  user?: any;
}) {
  const userInfo = useAuth();
  const [createFn] = useCreateUserProfileMutation();
  const [updateFn] = useUpdateUserProfileMutation();

  const data = props.user;
  const [validated, setValidated] = useState(false);
  const [isOpen, setIsOpen] = useState(props.isOpenPopup || false);

  const initialUserState = {
    id: data?.id || 0,
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    userCode: data?.userCode || "",
    location: data?.location || "",
    parentId: data?.parentId || 0,
    parentName: data?.parentName || "",
    email: data?.email || "",
    phoneNumber: data?.phoneNumber || "",
    countryCode: data?.countryCode || "+91",
    password: data?.password || "Aeronero@123",
    isDisabled: data?.isDisabled || false,
    address1: data?.address1 || "",
    address2: data?.address2 || "",
    state: data?.state || "",
    zipcode: data?.zipcode || "",
    country: data?.country || "",
    roles: !!data?.roles.length ? data?.roles[0] : "USER",
  };

  const [userInput, setUserInput] = useState(initialUserState);

  const handleSave = async () => {
    try {
      const roles = [userInput.roles.toUpperCase()];
      const userProfile = { ...userInput };
      userProfile.roles = roles;

      try {
        const result = !userProfile.id
          ? await createFn(userProfile).unwrap()
          : await updateFn(userProfile).unwrap();
        closeModal(result || true);
      } catch (error) {
        closeModal();
      }
    } catch {}
  };

  const closeModal = (result?: any) => {
    setIsOpen(false);
    setUserInput(initialUserState);
    setValidated(false);
    props.handleClose(result);
  };

  const handleInputChange = (event: any, valueField: string) => {
    setUserInput((prevState) => ({
      ...prevState,
      [valueField]: event.target.value,
    }));
  };

  const handleSelect = (e: any) => {
    setUserInput((prevState) => ({
      ...prevState,
      roles: e.target.value,
    }));
  };

  const handleCountryChange = (selectedCountryCode: string) => {
    setUserInput((prevState) => ({
      ...prevState,
      countryCode: selectedCountryCode,
    }));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    handleSave();
  };

  return (
    <div key={"user-modal-1"}>
      {isOpen && (
        <Modal
          show={isOpen}
          key={"user-modal-2"}
          centered
          onHide={closeModal}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-6">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Control
                      required
                      value={userInput.firstName}
                      type="text"
                      placeholder="First name"
                      onChange={(e) => handleInputChange(e, "firstName")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter first name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3" controlId="validationCustom02">
                    <Form.Control
                      required
                      value={userInput.lastName}
                      type="text"
                      placeholder="Last name"
                      onChange={(e) => handleInputChange(e, "lastName")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter last name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-6">
                  <InputGroup className="mb-3" hasValidation>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="outline-secondary"
                        id="dropdown-country"
                      >
                        {userInput.countryCode || "+91"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="country-code-dropdown-scroll">
                        {COUNTRYCODES.map((country, index) => (
                          <Dropdown.Item
                            className={
                              userInput.countryCode === country.code
                                ? "selected"
                                : ""
                            }
                            key={index}
                            onClick={() => handleCountryChange(country.code)}
                          >
                            ({country.code})-{country.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form.Control
                      onChange={(e) => handleInputChange(e, "phoneNumber")}
                      name="mobileNo"
                      type="tel"
                      inputMode="numeric"
                      value={userInput.phoneNumber}
                      required
                      pattern="[0-9]{4,15}"
                      placeholder="Mobile Number"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid mobile number.
                    </Form.Control.Feedback>
                  </InputGroup>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3" key="emailId">
                    <Form.Control
                      onChange={(e) => handleInputChange(e, "email")}
                      name="emailId"
                      type="email"
                      value={userInput.email}
                      required
                      pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                      placeholder="Email Id"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email address.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3" controlId="validationCustom04">
                    <Form.Select
                      required
                      value={userInput.roles}
                      onChange={handleSelect}
                      aria-label="Select roles"
                      disabled={!userInfo.isAdmin}
                    >
                      {ROLES.filter((r) =>
                        userInfo.isAdmin ? true : r === "User"
                      ).map((role, index) => (
                        <option key={index} value={role}>
                          {role}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Choose valid role.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3" controlId="address1">
                    <Form.Control
                      value={userInput.address1}
                      type="text"
                      placeholder="Address 1"
                      onChange={(e) => handleInputChange(e, "address1")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter address1.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3" controlId="address2">
                    <Form.Control
                      value={userInput.address2}
                      type="text"
                      placeholder="Address 2"
                      onChange={(e) => handleInputChange(e, "address2")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter address2.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3" controlId="location">
                    <Form.Control
                      value={userInput.location}
                      type="text"
                      placeholder="City"
                      onChange={(e) => handleInputChange(e, "location")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter location.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3" controlId="state">
                    <Form.Control
                      value={userInput.state}
                      type="text"
                      placeholder="State"
                      onChange={(e) => handleInputChange(e, "state")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter state.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3" controlId="country">
                    <Form.Control
                      value={userInput.country}
                      type="text"
                      placeholder="Country"
                      onChange={(e) => handleInputChange(e, "country")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter country.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3" controlId="zipcode">
                    <Form.Control
                      value={userInput.zipcode}
                      type="text"
                      placeholder="Zip Code"
                      onChange={(e) => handleInputChange(e, "zipcode")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter zip code.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" type="submit">
                {!userInput?.id ? "Save" : "Update"}
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </div>
  );
}

export default AddUser;
