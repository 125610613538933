import { useEffect, useState } from "react";
import { Button, Stack, Table } from "react-bootstrap";
import {
  NotificationMessageDetail,
  deleteNotificationConfig,
  getNotificationConfig,
} from "../../redux/apis/notificationConfigApi";
import TitleInfo from "../common/TitleInfo";
import NotificationConfiguration from "./notification-configuration-form";
import { debounce } from "lodash";

function NotificationConfigurationList() {
  const [notificationConfigList, setNotificationConfigList] = useState<
    NotificationMessageDetail[]
  >([]);
  const [notificationConfig, setNotificationConfig] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState(
    {} as NotificationMessageDetail
  );

  useEffect(() => {
    const delayedSearch = debounce(async () => {
      await refreshTableData();
    }, 300); // Adjust delay as needed

    delayedSearch();
    return delayedSearch.cancel;
  }, []);

  const refreshTableData = async (searchText?: string) => {
    try {
      const data = await getNotificationConfig();
      setNotificationConfigList(data);
    } catch (error) {
      console.error("Error fetching referrals:", error);
    }
  };

  const renderHeader = () => {
    return (
      <thead>
        <tr>
          <th>Message Type</th>
          <th>Send Type</th>
          <th>Comments</th>
          <th>Actions</th>
        </tr>
      </thead>
    );
  };

  const renderTableDataRow = (
    item: NotificationMessageDetail,
    index: number
  ) => {
    const uniqueKey = `user-${item.id}-${index}`;
    return (
      <tr key={uniqueKey}>
        <td>{item.messageType}</td>
        <td>{item.sendType}</td>
        <td>{item.name}</td>
        <td>
          {" "}
          <button
            className="btn btn-link"
            onClick={() => handleClickEdit(item)}
          >
            <i className="bi bi-pencil-square"></i>
          </button>
          <button className="btn btn-link" onClick={() => onDelete(item.id)}>
            <i className="bi bi-trash3-fill text-danger"></i>
          </button>
        </td>
      </tr>
    );
  };

  const onDelete = async (id: number) => {
    try {
      if (window.confirm("Are you sure you want to delete this item?")) {
        await deleteNotificationConfig(id);
        setNotificationConfigList(
          notificationConfigList.filter((config) => config.id !== id)
        );
      }
    } catch {}
  };

  const handleClickEdit = (item: NotificationMessageDetail) => {
    setNotificationConfig(true);
    setSelectedConfig(item);
  };

  const renderTableRows = () => {
    return notificationConfigList.map((item, index) => {
      return renderTableDataRow(item, index);
    });
  };

  const handleNotificationConfig = () => {
    setNotificationConfig(true);
  };

  const handleClose = () => {
    setNotificationConfig(false);
    setSelectedConfig({} as NotificationMessageDetail);
  };

  return (
    <>
      <TitleInfo title={"Notification Configuration"} />
      <Stack
        direction="horizontal"
        gap={3}
        className="justify-content-end mb-2"
      >
        <div className="flex-fill"></div>
        <Button
          variant="primary"
          className="btn-sm"
          onClick={handleNotificationConfig}
        >
          Add Configuration
        </Button>
      </Stack>
      {notificationConfig && (
        <NotificationConfiguration
          handleClose={handleClose}
          isOpenPopup={notificationConfig}
          selectedConfig={selectedConfig}
          refreshTableData={refreshTableData}
        />
      )}
      <Table responsive hover size="sm" className="custom-table">
        {renderHeader()}
        <tbody>{renderTableRows()}</tbody>
      </Table>
    </>
  );
}

export default NotificationConfigurationList;
