import { Middleware, MiddlewareAPI, isRejectedWithValue } from "@reduxjs/toolkit"
import { addToast } from "../slices/utilitySlice";
import { logout } from "../slices/accountSlice";


export const rtkQueryErrorLogger: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
        if (isRejectedWithValue(action)) {
            if (action.payload.status === 401) {
                api.dispatch(logout());
            }
            else {
                const response = action.payload.data;

                let errorMessage: any = {
                    messageType: "danger",
                    bodyText: 'payload' in action
                        ? ('errorList' in action && !!response?.errorList.length
                            ? response?.errorList.map((err: any) => err[Object.keys(err)[0]]).join(", ")
                            : response.errors[0]) : "Service error"
                };

                if ('payload' in action && 'errorList' in action) {
                    errorMessage.headerText = response.errors[0];
                }

                api.dispatch(addToast(errorMessage));
            }

        }

        return next(action)
    }