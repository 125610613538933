import React from "react";
import { Modal, Button } from "react-bootstrap";

type AppProps = {
  title: string;
  size: string;
  centered?: boolean;
  submitBtn?: string;
  cancelBtn?: string;
  children: React.ReactNode;
  formName?: string;
  onClose: () => void;
  onSubmit?: () => void;
};

function CustomModal(props: AppProps) {
  const {
    title,
    children,
    onClose,
    onSubmit,
    size,
    centered = true,
    formName = "",
    submitBtn = "Ok",
    cancelBtn = "Close",
  } = props;
  return (
    <Modal
      show={true}
      onHide={onClose}
      size="lg"
      centered={centered}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        {!!formName && (
          <Button variant="primary" form={formName} type="submit">
            {submitBtn}
          </Button>
        )}
        {!formName && (
          <Button variant="primary" type="submit" onClick={onSubmit}>
            {submitBtn}
          </Button>
        )}
        <Button variant="secondary" onClick={onClose}>
          {cancelBtn}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CustomModal;
