import { fetchWithInterceptor } from "../utils/fetchWithInterceptor";

const routeUrl = "users/profile-picture";

export const getProfilePicture = async (): Promise<
    any
> => {
    const response = await fetchWithInterceptor(routeUrl, {
        method: "GET"
    }, false);
    return !response.ok ? null : response.text();
};

export const uploadProfilePicture = async (
    file: any
): Promise<any> => {

    const formData = new FormData();
    formData.append("formFile", file); // Assuming your API expects a field named "profilePicture"

    const response = await fetchWithInterceptor(`${routeUrl}-upload`, {
        method: "POST",
        headers: {
            Accept: '*/*',
        },
        body: formData,
    });
    return response;
};

export const deleteProfilePicture = async (): Promise<any> => {
    const response = await fetchWithInterceptor(`${routeUrl}`, {
        method: "DELETE",
    });
    return response;
};

export const profilePictureDownload = async (): Promise<
    string
> => {
    const response = await fetchWithInterceptor(`${routeUrl}-download`);
    return response;
};
