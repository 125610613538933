import { Route, Routes } from "react-router-dom";
import AnonymousReferralForm from "../components/anonymous-referral/anonymous-referral";
import DeviceList from "../components/device/device-list";
import Dashboard from "../components/dsahboard/dashboard";
import EnterEmail from "../components/forget-password/recover-otp";
import Resetpassword from "../components/forget-password/reset-password";
import VerifyOTP from "../components/forget-password/verify-otp";
import NotificationConfigurationList from "../components/notification-configuration/notification-configuration-list";
import OrderDetailsList from "../components/order-details/order-detail-list";
import ReferralList from "../components/referral/referral-list";
import ServiceNotificationList from "../components/service-notification/service-notification-list";
import ServiceRequestList from "../components/service-request/service-request-list";
import Settings from "../components/settings/settings";
import UserProfile from "../components/user-profile/user-profile";
import UserList from "../components/user/user-list";
import LoadingIndicator from "../components/utlis/loading-indicator";
import Toasts from "../components/utlis/toast-component";
import { AccountLayout } from "../layouts";
import LayoutSection from "../layouts/LayoutSection";
import { Login, NotFound } from "../pages";

function AppRouter() {
  return (
    <>
      <Toasts></Toasts>
      <LoadingIndicator>
        <Routes>
          {/* <Route element={<MainLayout />}> */}
          <Route element={<LayoutSection />}>
            {/* <Route element={<MachineList />} path="/" /> */}
            <Route element={<Dashboard />} path="/" />
            <Route element={<Dashboard />} path="/dashboard" />
            <Route element={<DeviceList />} path="/machines" />
            <Route element={<UserList />} path="/users" />
            <Route element={<ReferralList />} path="/referral" />
            <Route element={<ServiceRequestList />} path="/service-request" />
            <Route element={<OrderDetailsList />} path="/order-details" />
            <Route
              element={<ServiceNotificationList />}
              path="/service-notification"
            />
            <Route
              element={<NotificationConfigurationList />}
              path="/notification-configuration"
            />
            <Route element={<UserProfile />} path="/user-profile" />
            <Route element={<Settings />} path="/settings" />
          </Route>
          <Route path="/account" element={<AccountLayout />}>
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="recover-otp" element={<EnterEmail />} />
            <Route path="verify-otp" element={<VerifyOTP />} />
            <Route path="reset-password" element={<Resetpassword />} />
            <Route
              path="anonymous-referral/:userId"
              element={<AnonymousReferralForm />}
            />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </LoadingIndicator>
    </>
  );
}

export default AppRouter;
