import { fetchWithInterceptor } from "../utils/fetchWithInterceptor";

export interface NotificationMessage {
  userId: number;
  id: number;
  messageDescription: string;
  messageTypeId: number;
  isRead: boolean;
}

export interface NotificationMessageDetail {
  notificationMessageId: number;
  id: number;
  sendType: string;
  sentTime: number;
  isSent: boolean;
}

const routeUrl = "notification";

export const getNotification = async (): Promise<NotificationMessage[]> => {
  const response = await fetchWithInterceptor(routeUrl);
  return response;
};

export const createNotification = async (
  data: NotificationMessage[]
): Promise<NotificationMessage[]> => {
  const response = await fetchWithInterceptor(routeUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response;
};
