import { ChangePasswordModel } from '../../components/common/model';
import { apiSlice } from '../slices/apiSlice';


export interface ForgotPasswordRequest {
  email: string
}

export interface VerifyCode {
  email: string
  code: string
  codeType: string
}

export interface NewPassword {
  email: string
  resetCode: string
  newPassword: string
}

export const forgotPasswordApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendEmailForOTP: builder.mutation<ForgotPasswordRequest, ForgotPasswordRequest>({
      query: (credentials) => ({
        url: `/account/forgot-password`,
        method: 'POST',
        body: credentials,
      }),
    }),
    verifyOTP: builder.mutation<VerifyCode, VerifyCode>({
      query: (credentials) => ({
        url: `/account/verify-code`,
        method: 'POST',
        body: credentials,
      }),
    }),
    createNewPassword: builder.mutation<NewPassword, NewPassword>({
      query: (credentials) => ({
        url: `/account/reset-password`,
        method: 'POST',
        body: credentials,
      }),
    }),
    changePassword: builder.mutation<ChangePasswordModel, ChangePasswordModel>({
      query(changePassword: ChangePasswordModel) {
        return {
          url: `/account/profile/change-password`,
          method: "PUT",
          body: changePassword,
        };
      },
    }),
  }),
});

export const {
  useSendEmailForOTPMutation,
  useVerifyOTPMutation,
  useCreateNewPasswordMutation,
  useChangePasswordMutation,
} = forgotPasswordApiSlice;



