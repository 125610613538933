import { useEffect, useState } from "react";
import { Button, FloatingLabel, Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import CustomInput from "../common/custom-input";
import AddDevice from "../device/add-device";
import AddUser from "../user/add-user";

function OrderDetailForm(props: {
  users: any;
  devices: any;
  orderDetail: any;
  validated: boolean;
  isManageDevice: boolean;
  isReturnDate: boolean;
  handleChangeInput: any;
  handleAutoComplete: (item: any, type: string) => void;
}) {
  const {
    users,
    devices,
    orderDetail,
    validated,
    isManageDevice,
    isReturnDate,
    handleChangeInput,
    handleAutoComplete,
  } = props;

  const [selectedUser, setSelectedUser] = useState<any>("");
  const [selectedDevice, setSelectedDevice] = useState("");
  const [isAddUserPopup, setIsAddUserPopup] = useState(false);
  const [isAddDevicePopup, setIsAddDevicePopup] = useState(false);

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: 58,
      minHeight: 58,
    }),
  };

  useEffect(() => {
    const userTemp =
      !!orderDetail.userId && !!users?.length
        ? users.find((u: any) => u.id === orderDetail.userId)
        : null;
    setSelectedUser(userTemp);

    const deviceTemp =
      !!orderDetail.userId && !!devices?.length
        ? devices.find((u: any) => u.id === orderDetail.deviceId)
        : null;

    setSelectedDevice(deviceTemp);

    return () => {};
  }, []);

  const handlePopupClose = (type: string) => {
    switch (type) {
      case "user":
        setIsAddUserPopup(false);
        break;
      case "machine":
        setIsAddDevicePopup(false);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {isAddUserPopup && (
        <AddUser
          handleClose={() => handlePopupClose("user")}
          isOpenPopup={isAddUserPopup}
        />
      )}
      {isAddDevicePopup && (
        <AddDevice
          handleClose={() => {
            handlePopupClose("machine");
          }}
          device={null}
        />
      )}
      <div className="row flex-fill">
        {!isReturnDate && (
          <>
            {/* add/edit order */}
            {!isManageDevice && (
              <>
                <div className="col-6">
                  <InputGroup className="flex-nowrap mb-3">
                    <Button
                      variant="primary"
                      id="button-addon1"
                      title="Add new user"
                      disabled={isManageDevice}
                      onClick={() => {
                        setIsAddUserPopup(true);
                      }}
                    >
                      Add
                    </Button>
                    <div className="flex-fill">
                      <Form.Group key="userId">
                        <Select
                          className="custom-atocomplete"
                          name="userId"
                          options={users}
                          onChange={(item) => {
                            handleAutoComplete(item?.id || 0, "userId");
                            item = item || { email: "", phoneNumber: "" };
                            setSelectedUser(item);
                          }}
                          value={selectedUser}
                          isClearable={true}
                          placeholder="Search user"
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option: any) => {
                            const label = !!option.id
                              ? `${option.firstName} ${option.lastName}`
                              : option.label;
                            return label;
                          }}
                          isOptionDisabled={() => false}
                          styles={customStyles}
                          isDisabled={isManageDevice}
                        />
                      </Form.Group>
                    </div>
                    {validated && !orderDetail.userId && (
                      <Form.Text className="text-danger">
                        Please select valid user
                      </Form.Text>
                    )}
                  </InputGroup>
                </div>
                <div className="col-6">
                  <CustomInput
                    label="Email ID"
                    placeholder="Enter Email ID"
                    name="email"
                    value={selectedUser?.email || ""}
                    disabled
                  />
                </div>
                <div className="col-6">
                  <CustomInput
                    label="Phone Number"
                    placeholder="Enter Phone Number"
                    name="phoneNumber"
                    value={selectedUser?.phoneNumber}
                    disabled
                  />
                </div>
              </>
            )}
            {/* manage machine */}
            <div className="col-6">
              <InputGroup className="flex-nowrap mb-3">
                <Button
                  variant="primary"
                  id="button-addon1"
                  title="Add new machine"
                  onClick={() => {
                    setIsAddDevicePopup(true);
                  }}
                >
                  Add
                </Button>
                <div className="flex-fill">
                  <Form.Group key="deviceId">
                    <Select
                      className="custom-atocomplete"
                      name="deviceId"
                      options={devices}
                      onChange={(item: any) => {
                        handleAutoComplete(item?.id || 0, "deviceId");
                        setSelectedDevice(item);
                      }}
                      value={selectedDevice}
                      isClearable={true}
                      placeholder="Search machine"
                      getOptionLabel={(option: any) => {
                        const label = !!option.id
                          ? `${option.deviceId} (${option.deviceName})`
                          : option.label;
                        return label;
                      }}
                      getOptionValue={(option: any) => option.id}
                      styles={customStyles}
                    />
                  </Form.Group>
                </div>
                {validated && !orderDetail.deviceId && (
                  <Form.Text className="text-danger">
                    Please select valid machine
                  </Form.Text>
                )}
              </InputGroup>
            </div>
            <div className="col-6 mb-3">
              <FloatingLabel
                controlId="floatingInput"
                label="Purchase Date"
                className="mb-3"
              >
                <Form.Control
                  onChange={handleChangeInput}
                  name="purchaseDate"
                  type="date"
                  value={orderDetail.purchaseDate}
                  placeholder="Purchase Date"
                  className="form-control-sm"
                />
                {validated && !orderDetail.purchaseDate && (
                  <Form.Text className="text-danger">
                    Please select valid purchase date
                  </Form.Text>
                )}
              </FloatingLabel>
            </div>
            <div className="col-6">
              <FloatingLabel
                controlId="floatingInput"
                label="Warranty Expire Date"
                className="mb-3"
              >
                <Form.Control
                  onChange={handleChangeInput}
                  name="warrantyExpireDate"
                  type="date"
                  value={orderDetail.warrantyExpireDate}
                  placeholder="Warranty Expire Date"
                  className="form-control-sm"
                />
                {validated && !orderDetail.warrantyExpireDate && (
                  <Form.Text className="text-danger">
                    Please select valid warranty expire date
                  </Form.Text>
                )}
                {validated &&
                  !!orderDetail.warrantyExpireDate &&
                  !!orderDetail.purchaseDate &&
                  orderDetail.purchaseDate >=
                    orderDetail.warrantyExpireDate && (
                    <Form.Text className="text-danger">
                      Warranty expire date should greater than purchase date
                    </Form.Text>
                  )}
              </FloatingLabel>
            </div>
          </>
        )}
        {/* manage return date */}
        {isReturnDate && (
          <>
            <div className="col-6">
              <FloatingLabel
                controlId="floatingInput"
                label="Return Date"
                className="mb-3"
              >
                <Form.Control
                  onChange={handleChangeInput}
                  name="returnDate"
                  type="date"
                  value={orderDetail.returnDate}
                  placeholder="Return Date"
                  className="form-control-sm"
                />
                {validated && !orderDetail.returnDate && (
                  <Form.Text className="text-danger">
                    Please select valid return date
                  </Form.Text>
                )}
              </FloatingLabel>
            </div>
            <div className="col-6">
              <FloatingLabel
                controlId="floatingTextarea"
                label="Comments"
                className="mb-3"
              >
                <Form.Control
                  onChange={handleChangeInput}
                  name="comments"
                  value={orderDetail.comments}
                  as="textarea"
                  placeholder="Leave a comment here"
                  className="form-control-sm"
                />
              </FloatingLabel>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default OrderDetailForm;
