import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, Stack } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useChangePasswordMutation } from "../../redux/apis/forgotPasswordApi";
import { useAuth } from "../../redux/hooks/useAuth";
import { logout } from "../../redux/slices/accountSlice";
import { apiSlice } from "../../redux/slices/apiSlice";
import { ChangePasswordModel } from "../common/model";

function ChangePassword() {
  const { userInfo } = useAuth();
  const dispatch = useDispatch();
  const [changePasswordFn] = useChangePasswordMutation();

  // form validation rules
  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    // .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  // get functions to build form with useForm() hook
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const onLogout = () => {
    try {
      dispatch(logout());
      dispatch(apiSlice.util.resetApiState());
    } catch (error) {
      console.error("Error logout:", error);
    }
  };

  const onSubmit: SubmitHandler<any> = async (fromData) => {
    try {
      const data = {
        userId: userInfo?.id,
        password: fromData.confirmPassword,
      } as ChangePasswordModel;

      const result = await changePasswordFn(data).unwrap();
      const toast = document.getElementById("changePasswordToast");

      if (toast) {
        toast.classList.add("show");
        setTimeout(() => {
          onLogout();
          // navigate("/account/login");
        }, 2000);
      }
    } catch (error) {
      console.error("Error change password:", error);
    }

    return false;
  };

  return (
    <Card className="col-8 mx-auto my-5">
      <Card.Header as="h5" className="text-center">
        Change Password
      </Card.Header>
      <Card.Body>
        <div>
          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={4}>
              <Form.Group controlId="password">
                <Form.Control
                  {...register("password")}
                  type="password"
                  autoComplete="off"
                  size="lg"
                  placeholder="New Password"
                  required
                />
                {errors.password && (
                  <Form.Text className="text-danger">
                    {errors.password.message}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group controlId="confirmPassword">
                <Form.Control
                  type="password"
                  {...register("confirmPassword")}
                  autoComplete="off"
                  size="lg"
                  placeholder="Confirm Password"
                />
                {errors.confirmPassword && (
                  <Form.Text className="text-danger">
                    {errors.confirmPassword.message}
                  </Form.Text>
                )}
              </Form.Group>
              <div className="text-end">
                <Button variant="primary" size="lg" type="submit">
                  Submit
                </Button>
              </div>
              <div
                id="changePasswordToast"
                className="toast align-items-center text-white bg-success position-fixed top-0 start-50 translate-middle-x"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
              >
                <div className="d-flex">
                  <div className="toast-body">
                    Password changed successfully!
                  </div>
                </div>
              </div>
            </Stack>
          </Form>
        </div>
      </Card.Body>
    </Card>
  );
}

export default ChangePassword;
