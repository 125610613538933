import _ from "lodash";
import { useEffect, useState } from "react";
import { Card, Col, Nav, Row, Stack, Tooltip } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import {
  getDashboardSummary,
  getDeviceReadingSummary,
} from "../../redux/apis/dashbordApi";
import { useGetAllDevicesQuery } from "../../redux/apis/deviceApi";
import { useAuth } from "../../redux/hooks/useAuth";
import CustomDropdown from "../common/custom-dropdown";
import {
  DASHBOAD_SUMMARY_TYPES,
  DASHBOAD_TEMPLATE_TYPES,
  DURATION_TYPES,
} from "../common/helper/helper";
import "./dashboard.scss";

function Dashboard() {
  const user = useAuth();

  // Access the 'route params' query parameter
  const [routeParams, setRouteParams] = useSearchParams();
  const roureQuery = routeParams.get("machineId");

  const summaryValue = DASHBOAD_SUMMARY_TYPES;
  const templatesData = DASHBOAD_TEMPLATE_TYPES;
  const durationTypes = DURATION_TYPES;

  const [summaries, setSummaries] = useState<any[]>(summaryValue);
  const [templates, setTemplates] = useState<any[]>(templatesData);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const deviceList = useGetAllDevicesQuery(user.account.user?.id || 0).data;
  const devices = _.map(_.filter(deviceList, m => m.statusCode === 'SOLD'), "deviceId");

  const [filterValue, setFilterValue] = useState({
    duration: "Day",
    machine: "",
  });

  const handleSelect = (eventKey: any, property: string) => {
    setFilterValue((prevState) => ({
      ...prevState,
      [property]: eventKey,
    }));
  };

  useEffect(() => {
    const delayedSearch = _.debounce(async () => {
      if (devices.length) {
        await handleFetchSummary();
        const defaultMachineId = roureQuery || devices[0] || "";
        handleSelect(defaultMachineId, "machine");
      }
    }, 300);

    delayedSearch();

    return () => {
      delayedSearch.cancel();
    };
  }, [deviceList]);

  useEffect(() => {
    const delayedFetch = _.debounce(async () => {
      await handleFetchDeviceReadings();
    }, 500);

    delayedFetch();

    return () => {
      delayedFetch.cancel();
    };
  }, [filterValue]);

  const handleFetchSummary = async () => {
    try {
      const summaries = await getDashboardSummary();

      summaryValue[0].value = summaries?.noOfCustomers || 0;
      summaryValue[1].value = summaries?.noOfPartners || 0;
      summaryValue[2].value = summaries?.noOfAvailableMachines || 0;
      summaryValue[3].value = summaries?.noOfSoldMachines || 0;

      setSummaries([...summaryValue]);
    } catch (error) {
      console.error("Error fetching summaries:", error);
    }
  };

  const handleFetchDeviceReadings = async () => {
    if (!filterValue.machine || !filterValue.duration || !deviceList?.length) {
      return;
    }

    setIsLoading(true);
    try {
      const machineId = !!filterValue.machine
        ? deviceList.find((d) => d.deviceId === filterValue.machine)?.id!
        : deviceList[0].id;

      const result = await getDeviceReadingSummary(
        machineId,
        filterValue.duration
      );
      handleChartData(result);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching device readings:", error);
    }
  };

  const handleChartData = (data: any) => {
    const mappedData = templates.map((t) => {
      t.avg = data[`${t.col}Avg`] || 0;
      t.max = data[`${t.col}Max`] || 0;
      t.min = data[`${t.col}Min`] || 0;

      if (!!data.items.length) {
        const chartData = data.items.map((i: any) => {
          return {
            time: i.readingDateTimePart,
            avg: data[`${t.col}Avg`],
            max: data[`${t.col}Max`],
            min: data[`${t.col}Min`],
          };
        });
        t.data = chartData;
      } else {
        t.data = [
          {
            time: 0,
            avg: 0,
            max: 0,
            min: 0,
          },
        ];
      }

      return t;
    });
    setTemplates(mappedData);
    setIsLoading(false);
  };

  const navigate = useNavigate();

  const onRedirect = (query: string) => {
    if (!query) {
      return;
    }

    navigate(query);
  };

  const renderSummaries = () => {
    return (
      <Card border="light" className="my-4 p-3 dashboard-card">
        <Card.Body>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2">
            <div>
              <h1 className="h4">Overview</h1>
              <p className="text-secondary page-sub-title">
                Here is the statistics of Customers, Distributors and Machines
              </p>
            </div>
          </div>

          <Row xs={1} sm={2} md={4} className="g-4 px-2">
            {summaries
              .filter((s) => (!user.isAdmin ? s.tile !== "Partners" : true))
              .map((item, idx) => (
                <Col key={idx}>
                  <Card
                    className={`dashboard-sub-card card-bg-${idx + 1}`}
                    onClick={() => onRedirect(item.link)}
                  >
                    <Card.Body>
                      <Stack gap={3}>
                        <button className="btn btn-info rounded-circle">
                          <i className={`bi ${item.icon}`}></i>
                        </button>
                        <div className="s-c-tile-1">{item.tile}</div>
                        <div className="s-c-tile-2">
                          {item.subtile} {item.value}
                        </div>
                        <div className="s-c-tile-3">{item.info}</div>
                      </Stack>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const renderTemplateFilter = () => {
    return (
      <Stack className="px-4" direction="horizontal" gap={3}>
        <div>
          <h1 className="h4">Machine Statistics</h1>
          <p className="text-secondary page-sub-title">
            Here is the statistics of machines
          </p>
        </div>
        <div className="p-2 ms-auto">
          <Nav
            className="duration-types"
            variant="pills"
            defaultActiveKey={filterValue.duration}
            onSelect={(value) => handleSelect(value, "duration")}
          >
            {durationTypes.map((item, idx) => (
              <Nav.Item key={idx}>
                <Nav.Link eventKey={item}>{item}</Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </div>
        <div className="p-2 ms-auto">
          <CustomDropdown
            title={filterValue.machine || "Machine Id"}
            options={devices}
            onSelect={(value) => handleSelect(value, "machine")}
          />
        </div>
      </Stack>
    );
  };

  const renderChartTemplates = () => {
    return (
      <div>
        <Row xs={1} sm={2} md={3} className="g-5 p-2 mb-4 pb-4">
          {templates.map((item, idx) => (
            <Col key={idx}>
              <Card className={`template-card shadow-sm`}>
                <Card.Body>
                  <Stack direction="horizontal" gap={2} className="p-2">
                    <div>
                      <div className="t-c-icon">
                        <i className={`bi ${item.icon}`}></i>
                      </div>
                    </div>
                    <div className="flex-fill t-c-title">{item.tile}</div>
                    <div className="t-c-sub-title">
                      {item.avg}
                      {item.measure}
                    </div>
                  </Stack>

                  <div
                    style={{ width: "100%", height: 200, padding: "15px 0" }}
                  >
                    <ResponsiveContainer>
                      <ComposedChart
                        data={item.data}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="time" scale="band" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Area
                          type="monotone"
                          dataKey="avg"
                          stroke="rgb(53, 162, 235)"
                          fill="rgba(53, 162, 235, 0.5)"
                        />
                        <Line type="monotone" dataKey="max" stroke="#82ca9d" />
                        <Line type="monotone" dataKey="min" stroke="#ff7300" />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                  <Stack direction="horizontal" gap={2} className="t-c-info">
                    <div className="t-c-info-label">Highest</div>
                    <div className="ms-auto t-c-info-value">
                      {item.max}
                      {item.measure}
                    </div>
                  </Stack>
                  <Stack direction="horizontal" gap={2} className="t-c-info">
                    <small className="t-c-info-label">Lowest</small>
                    <div className="ms-auto t-c-info-value">
                      {item.min}
                      {item.measure}
                    </div>
                  </Stack>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  return (
    <>
      {/* summaries */}
      {renderSummaries()}
      {/* Machine Statistics */}
      {renderTemplateFilter()}
      {/* charts */}
      <LoadingOverlay
        active={isLoading}
        spinner={<BounceLoader />}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.7)",
          }),
          wrapper: (base) => ({
            ...base,
            top: "30px",
          }),
        }}
      >
        {renderChartTemplates()}
      </LoadingOverlay>
    </>
  );
}

export default Dashboard;
