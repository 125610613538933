import { Button, Form, InputGroup, Stack, Table } from "react-bootstrap";

import _, { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Referral,
  deleteReferral,
  getReferralBySearch,
} from "../../redux/apis/referralApi";
import { useAuth } from "../../redux/hooks/useAuth";
import { RootState } from "../../redux/store";
import TitleInfo from "../common/TitleInfo";
import AddReferral from "./add-referral";

function ReferralList() {
  const user = useAuth();
  const [showNewReferral, setShowNewReferral] = useState(false);
  const [selectedReferral, setSelectedReferral] = useState(null);
  const [referrals, setReferrals] = useState<Referral[]>([]);
  const accessToken = useSelector(
    (state: RootState) => state.account.accessToken
  );

  const handleSearch = async (event: any) => {
    refreshTableData(event?.target?.value);
  };

  const debouncedResults = useMemo(() => {
    return _.debounce(handleSearch, 300);
  }, []);

  useEffect(() => {
    const delayedSearch = debounce(async () => {
      await refreshTableData();
    }, 300); // Adjust delay as needed

    delayedSearch();
    return delayedSearch.cancel;
  }, []);

  const onDelete = async (id: any) => {
    try {
      if (window.confirm("Are you sure you want to delete this item?")) {
        await deleteReferral(accessToken, id);
        // Update state after successful deletion
        setReferrals(referrals.filter((referral) => referral.id !== id));
      }
    } catch (error) {
      console.error("Error deleting referral:", error);
    }
  };

  const handleClickEdit = (item: any) => {
    setShowNewReferral(true);
    setSelectedReferral(item);
  };

  const handleClose = () => {
    setShowNewReferral(false);
  };

  const renderHeader = () => {
    return (
      <thead>
        <tr>
          <th>Name</th>
          <th>Email Address</th>
          <th>Phone Number</th>
          <th>Lead By</th>
          <th>Lead Via</th>
          <th>What you are looking for?</th>
          <th>How soon you are going to buy?</th>
          <th>Status</th>
          <th style={{ width: "95px" }}>Actions</th>
        </tr>
      </thead>
    );
  };

  const renderTableDataRow = (item: Referral, index: number) => {
    const uniqueKey = `user-${item.id}-${index}`;
    return (
      <tr key={uniqueKey}>
        <td>
          {item.firstName}&nbsp;
          {item.lastName}
        </td>
        <td>{item.emailId}</td>
        <td>
          {item.countryCode || 91}-{item.mobileNo}
        </td>
        <td>
          {item.userProfile?.firstName + " " + item.userProfile?.lastName}
        </td>
        <td>{item.channel}</td>
        <td>{item.lookingFor}</td>
        <td>{item.buyPeriod}</td>
        <td>{item.status}</td>
        <td>
          <Stack direction="horizontal" gap={1}>
            <Button
              variant="link"
              onClick={() => handleClickEdit(item)}
              title="Edit"
            >
              <i className="bi bi-pencil-square"></i>
            </Button>
            <Button
              variant="link"
              onClick={() => onDelete(item.id)}
              title="Delete"
            >
              <i className="bi bi-trash3-fill text-danger"></i>
            </Button>
          </Stack>
        </td>
      </tr>
    );
  };

  const renderTableRows = () => {
    return referrals?.map((item, index) => {
      return renderTableDataRow(item, index);
    });
  };

  const refreshTableData = async (searchText?: string) => {
    try {
      const search = {
        searchText: searchText,
        role: "",
        currentUserId: user?.account?.user?.id!,
        id: 0,
      };
      const data = await getReferralBySearch(accessToken, search);
      setReferrals(data);
    } catch (error) {
      console.error("Error fetching referrals:", error);
    }
  };

  return (
    <>
      <TitleInfo
        title={"Lead List"}
        subTitle={"Here is the list of all the Leads"}
      />
      <Stack
        direction="horizontal"
        gap={3}
        className="justify-content-end mb-2"
      >
        <div className="flex-fill"></div>
        <InputGroup size="sm" className="w-50">
          <InputGroup.Text id="search-addon1">
            <i className="bi bi-search"></i>
          </InputGroup.Text>
          <Form.Control
            placeholder="Search Name, Email or Lead By"
            onChange={debouncedResults}
          />
        </InputGroup>
        <div className="vr" />
        <Button
          className="btn-sm"
          onClick={() => {
            setSelectedReferral(null);
            setShowNewReferral(true);
          }}
        >
          Add Lead
        </Button>
      </Stack>
      {showNewReferral && (
        <AddReferral
          handleClose={handleClose}
          referral={selectedReferral}
          refreshTableData={refreshTableData}
        />
      )}
      <Table responsive hover size="sm" className="custom-table">
        {renderHeader()}
        <tbody>{renderTableRows()}</tbody>
      </Table>
    </>
  );
}

export default ReferralList;
