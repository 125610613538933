import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { getLovs } from "../../redux/apis/lovApi";
import { createNotification } from "../../redux/apis/notificationApi";
import { MasterValue } from "../common/model";

function SendNotification(props: {
  handleClose: () => void;
  isOpenPopup: boolean;
  selectedItemData: any;
  resetSelectedData: any;
}) {
  const initialUserState = {
    messageTypeId: "",
    messageDescription: "",
    id: 0,
    isRead: false,
  };

  const [validated, setValidated] = useState(false);
  const [isOpen, setIsOpen] = useState(props.isOpenPopup || false);
  const [userInput, setUserInput] = useState(initialUserState);
  const [notificationTypeLov, setNotificationTypeLov] = useState<MasterValue[]>(
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const lovData = await getLovs();
        setNotificationTypeLov(
          lovData.filter((lo) => lo.listName === "NotificationType")
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      const notifications: any[] = [];
      props.selectedItemData.forEach((n: any) => {
        const notification = {} as any;
        notification.userId = n.userId;
        notification.messageTypeId = notificationTypeLov.find(
          (se) => se.lovCode === "ORDER"
        )?.id;
        notification.messageDescription = userInput.messageDescription;
        notification.isRead = userInput.isRead;
        notification.messageId = n.id;
        notifications.push(notification);
      });
      const result = await createNotification(notifications);
      props.resetSelectedData();
      result && closeModal();
    } catch {}
  };

  const closeModal = () => {
    setIsOpen(false);
    setUserInput(initialUserState);
    setValidated(false);
    props.handleClose();
  };

  const handleInputChange = (event: any, valueField: string) => {
    setUserInput((prevState) => ({
      ...prevState,
      [valueField]: event.target.value,
    }));
  };

  const handleSelect = (e: any) => {
    setUserInput((prevState) => ({
      ...prevState,
      messageTypeId: e.target.value,
    }));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    handleSave();
  };

  return (
    <div key={"user-modal-1"}>
      {isOpen && (
        <Modal
          show={isOpen}
          key={"user-modal-2"}
          centered
          onHide={closeModal}
          backdrop="static"
          keyboard={false}
        >
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Send Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-12">
                  <Form.Group className="mb-3 p-2" key="messageTypeId">
                    <Form.Select
                      name="messageTypeId"
                      value={userInput.messageTypeId}
                      onChange={handleSelect}
                      required
                      aria-label="Message Type"
                    >
                      {/* <option value="">Message Type</option> */}
                      {notificationTypeLov?.map(
                        (category: MasterValue, index) => (
                          <option key={index} value={category.id}>
                            {category.lovName}
                          </option>
                        )
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Choose Message Type.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-12">
                  <Form.Group
                    className="mb-3 p-2"
                    controlId="messageDescription"
                  >
                    <Form.Control
                      // required
                      value={userInput.messageDescription}
                      as="textarea"
                      rows={2}
                      placeholder="Message Description"
                      onChange={(e) =>
                        handleInputChange(e, "messageDescription")
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter Message Description.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                Save
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </div>
  );
}

export default SendNotification;
