import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserProfile } from "../../components/common/model";
import { RootState } from "../store";
import { LoginResponse } from "./apiSlice";

export interface Account {
  tokenType?: string;
  accessToken?: string;
  expiresIn?: number;
  refreshToken?: number;
  user?: UserProfile;
  isAuthenticated: boolean;
  resetUserInfo?: ResetUserInfo;
}

export interface ResetUserInfo {
  email?: string;
  code?: string;
  codeType?: string;
}

const initialState: Account = localStorage.getItem("account")
  ? JSON.parse(localStorage.getItem("account") || "")
  : {
      isAuthenticated: false,
      resetUserInfo : {
        email: "",
        code:"",
        codeType:""
      }
    };

if (initialState.accessToken) {
  initialState.isAuthenticated = true;
}

export const accountSlice = createSlice({
  name: "account",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAccount: (state, accountInfo: PayloadAction<LoginResponse>) => {
      state.isAuthenticated = true;
      state.expiresIn = accountInfo.payload.expiresIn;
      state.accessToken = accountInfo.payload.accessToken;
      state.refreshToken = accountInfo.payload.refreshToken;
      localStorage.setItem("account", JSON.stringify(accountInfo.payload));
    },
    setUser: (state, accountInfo: PayloadAction<UserProfile>) => {
      state.user = accountInfo.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.accessToken = undefined;
      state.expiresIn = undefined;
      state.refreshToken = undefined;
      localStorage.removeItem("account");
    },
    setResetPasswordUser: ( state, accountInfo: PayloadAction<ResetUserInfo> ) => {
      state.resetUserInfo = accountInfo.payload;
    },
  },
});

export const { setAccount, setUser, logout, setResetPasswordUser } = accountSlice.actions;

export const selectAccount = (state: RootState) => state.account;

export default accountSlice.reducer;
