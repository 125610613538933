import { useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useUpdateDeviceMutation } from "../../redux/apis/deviceApi";

function DeviceSettings(props: {
  handleClose: () => void;
  isOpenPopup: boolean;
  device: any;
}) {
  const [updateFn] = useUpdateDeviceMutation();
  const [validated, setValidated] = useState(false);
  const [isOpen, setIsOpen] = useState(props.isOpenPopup || false);
  const deviceData = props.device;
  const initialUserState = {
    minEvaporatorCoilTemperatureThreshold:
      deviceData?.minEvaporatorCoilTemperatureThreshold,
    maxEvaporatorCoilTemperatureThreshold:
      deviceData?.maxEvaporatorCoilTemperatureThreshold,
    periodicDetailSendInterval: deviceData?.periodicDetailSendInterval,
    deviceFirmwareVersion: deviceData?.deviceFirmwareVersion,
  };

  const [userInput, setUserInput] = useState(initialUserState);

  const handleSave = async () => {
    try {
      const statusData = {
        ...deviceData,
        minEvaporatorCoilTemperatureThreshold:
          userInput?.minEvaporatorCoilTemperatureThreshold,
        maxEvaporatorCoilTemperatureThreshold:
          userInput?.maxEvaporatorCoilTemperatureThreshold,
        periodicDetailSendInterval: userInput?.periodicDetailSendInterval,
        deviceFirmwareVersion: userInput.deviceFirmwareVersion,
      } as any;

      await updateFn(statusData).unwrap();
      //   props.refreshTableData() &&
      closeModal();
    } catch {}
  };

  const closeModal = () => {
    setIsOpen(false);
    setUserInput(initialUserState);
    setValidated(false);
    props.handleClose();
  };

  const handleInputChange = (event: any, valueField: string) => {
    setUserInput((prevState) => ({
      ...prevState,
      [valueField]: event.target.value,
    }));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    handleSave();
  };

  return (
    <div key={"user-modal-1"}>
      {isOpen && (
        <Modal
          show={isOpen}
          key={"user-modal-2"}
          centered
          onHide={closeModal}
          backdrop="static"
          keyboard={false}
        >
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Manage Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-6">
                  <Form.Group className="mb-3" key="deviceId">
                    <Form.Label>Machine ID</Form.Label>
                    <Form.Control
                      onChange={(e) => handleInputChange(e, "deviceId")}
                      name="deviceId"
                      type="text"
                      value={deviceData.deviceId}
                      placeholder="Machine ID"
                      disabled
                    />
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3" key="deviceName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      onChange={(e) => handleInputChange(e, "deviceName")}
                      required
                      name="deviceName"
                      type="text"
                      value={deviceData.deviceName}
                      placeholder="Machine Name"
                      disabled
                    />
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3" key="deviceFirmwareVersion">
                    <Form.Label>Machine Version</Form.Label>
                    <Form.Control
                      name="deviceFirmwareVersion"
                      value={userInput.deviceFirmwareVersion}
                      type="text"
                      placeholder="Machine Firmware Version"
                      onChange={(e) =>
                        handleInputChange(e, "deviceFirmwareVersion")
                      }
                    />
                  </Form.Group>
                </div>

                <div className="col-6">
                  <Form.Group className="mb-3">
                    <Form.Label>Eva Coil Temp Threshold</Form.Label>
                    <InputGroup>
                      <Form.Control
                        key="evaporatorCoilTemperatureThresholdmin"
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "minEvaporatorCoilTemperatureThreshold"
                          )
                        }
                        name="minEvaporatorCoilTemperatureThreshold"
                        value={userInput.minEvaporatorCoilTemperatureThreshold}
                        type="number"
                        placeholder="Min"
                        min={0}
                        required
                        isInvalid={
                          validated &&
                          !userInput.minEvaporatorCoilTemperatureThreshold
                        }
                      />
                      <InputGroup.Text>-</InputGroup.Text>
                      <Form.Control
                        key="evaporatorCoilTemperatureThresholdMax"
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "maxEvaporatorCoilTemperatureThreshold"
                          )
                        }
                        name="maxEvaporatorCoilTemperatureThreshold"
                        value={userInput.maxEvaporatorCoilTemperatureThreshold}
                        type="number"
                        placeholder="Max"
                        min={1}
                        required
                        isInvalid={
                          validated &&
                          !userInput.minEvaporatorCoilTemperatureThreshold
                        }
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3" key="periodicDetailSendInterval">
                    <Form.Label>Periodic Detail Interval</Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        handleInputChange(e, "periodicDetailSendInterval")
                      }
                      name="periodicDetailSendInterval"
                      value={userInput.periodicDetailSendInterval}
                      type="number"
                      required
                      placeholder="Periodic Detail Send Interval"
                    />
                    <Form.Control.Feedback type="invalid">
                      Periodic detail interval is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" type="submit">
                Save
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </div>
  );
}

export default DeviceSettings;
