import { Form } from "react-bootstrap";

type AppProps = {
  id: number;
  userId: number;
  deviceId: string;
  deviceName: string;
  deviceFirmwareVersion: string;
  timezone: string;
  region: string;
  location: string;
  minEvaporatorCoilTemperatureThreshold: number;
  maxEvaporatorCoilTemperatureThreshold: number;
  periodicDetailSendInterval: number;
  isActive: any;
};

function DeviceForm(props: {
  device: AppProps;
  handleChangeInput: any;
  handleSelectUser: any;
  isFormValid: boolean;
}) {
  const { device, handleChangeInput, handleSelectUser, isFormValid } = props;

  return (
    <div className="row">
      <div className="col-6">
        <Form.Group className="mb-3" key="deviceId">
          <Form.Label>Machine ID</Form.Label>
          <Form.Control
            onChange={handleChangeInput}
            name="deviceId"
            type="text"
            isInvalid={isFormValid && !device.deviceId}
            value={device.deviceId}
            placeholder="Machine ID"
          />
          <Form.Control.Feedback type="invalid">
            Please enter machine id.
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      <div className="col-6">
        <Form.Group className="mb-3" key="deviceName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            onChange={handleChangeInput}
            required
            isInvalid={isFormValid && !device.deviceName}
            name="deviceName"
            type="text"
            value={device.deviceName}
            placeholder="Machine Name"
          />
          <Form.Control.Feedback type="invalid">
            Please enter machine name.
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      {/* <div className="col-6">
        <Form.Group className="mb-3" key="region">
          <Form.Label>Region</Form.Label>
          <Form.Control
            onChange={handleChangeInput}
            name="region"
            value={device.region}
            type="text"
            placeholder="Region"
          />
        </Form.Group>
      </div>
      <div className="col-6">
        <Form.Group className="mb-3" key="location">
          <Form.Label>Location</Form.Label>
          <Form.Control
            onChange={handleChangeInput}
            name="location"
            value={device.location}
            type="text"
            placeholder="Location"
          />
        </Form.Group>
      </div> */}
    </div>
  );
}

export default DeviceForm;
