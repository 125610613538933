import { SearchModel, UserProfile } from '../../components/common/model';
import { apiSlice } from '../slices/apiSlice';

const commonConstants = {
  urlSingularPrefix: "users",
  tagType: "UserProfiles",
};

export const userProfileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserProfileById: builder.query<UserProfile, number>({
      query(id) {
        return {
          url: `/${commonConstants.urlSingularPrefix}/${id}`,
          credentials: "include",
        };
      },
      providesTags: (result, error, id) => [{ type: "UserProfiles", id }],
    }),
    getAllUserProfiles: builder.query<UserProfile[], void>({
      query() {
        return {
          url: commonConstants.urlSingularPrefix,
        };
      },
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }: any) => ({
              type: "UserProfiles" as const,
              id,
            })),
            { type: "UserProfiles", id: "LIST" },
          ]
          : [{ type: "UserProfiles", id: "LIST" }],
    }),
    createUser: builder.mutation<UserProfile, UserProfile>({
      query(user) {
        return {
          url: commonConstants.urlSingularPrefix,
          method: "POST",
          body: user,
        };
      },
      invalidatesTags: [{ type: "UserProfiles", id: "LIST" }],
    }),
    createUserProfile: builder.mutation<UserProfile, UserProfile>({
      query(user) {
        return {
          url: `/${commonConstants.urlSingularPrefix}/profile`,
          method: "POST",
          body: user,
        };
      },
      invalidatesTags: [{ type: "UserProfiles", id: "LIST" }],
    }),
    updateUserProfile: builder.mutation<UserProfile, UserProfile>({
      query(user: UserProfile) {
        return {
          url: `/${commonConstants.urlSingularPrefix}/profile/${user.id}`,
          method: "PUT",
          body: user,
        };
      },
      invalidatesTags: (result, error, { userId }) =>
        result
          ? [
            { type: "UserProfiles", userId },
            { type: "UserProfiles", id: "LIST" },
          ]
          : [{ type: "UserProfiles", id: "LIST" }],
    }),
    deleteUser: builder.mutation<UserProfile, number>({
      query(id) {
        return {
          url: `/${commonConstants.urlSingularPrefix}/${id}`,
          method: "Delete",
        };
      },
      invalidatesTags: [{ type: "UserProfiles", id: "LIST" }],
    }),
    searchUsers: builder.mutation<UserProfile[], SearchModel>({
      query(searchModel) {
        return {
          url: `/${commonConstants.urlSingularPrefix}/search`,
          method: "POST",
          body: searchModel,
        };
      },
      invalidatesTags: [{ type: "UserProfiles", id: "LIST" }],
    })
  }),
});

export const {
  useCreateUserMutation,
  useCreateUserProfileMutation,
  useUpdateUserProfileMutation,
  useGetAllUserProfilesQuery,
  useGetUserProfileByIdQuery,
  useDeleteUserMutation,
  useSearchUsersMutation,
} = userProfileApiSlice;

