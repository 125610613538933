import { useEffect, useState } from "react";
import { Dropdown, Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { getLovs } from "../../redux/apis/lovApi";
import { useGetAllUserProfilesQuery } from "../../redux/apis/userProfileApi";
import { COUNTRYCODES } from "../common/helper/helper";
import { MasterValue } from "../common/model";

function ReferralForm(props: {
  referral: any;
  handleChangeInput: any;
  handleChangeSelect: any;
  IsFormValid: any;
  IsEmailValid: any;
  IsMobileValid: any;
  handleAutoComplete: any;
  handleCountryChange: any;
}) {
  const {
    referral,
    handleChangeInput,
    handleChangeSelect,
    IsFormValid,
    IsEmailValid,
    IsMobileValid,
    handleAutoComplete,
    handleCountryChange,
  } = props;

  const [validated, setValidated] = useState(false);
  const users = useGetAllUserProfilesQuery();
  const [referralChannelLov, setReferralChannelLov] = useState<MasterValue[]>(
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const lovData = await getLovs();
        setReferralChannelLov(
          lovData.filter((lo) => lo.listName === "ReferralChannel")
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const referrals = users.data?.map((item: any) => ({
    value: item.id,
    label: item.firstName + " " + item.lastName,
    data: item,
  }));

  return (
    <div className="row">
      <div className="col-6">
        <Form.Group className="mb-3 p-2" key="firstName">
          <Form.Control
            onChange={handleChangeInput}
            name="firstName"
            type="text"
            value={referral.firstName}
            required
            placeholder="First Name"
          />
          {IsFormValid && referral.firstName === "" && (
            <Form.Text className="text-danger">
              First name is required
            </Form.Text>
          )}
        </Form.Group>
      </div>
      <div className="col-6">
        <Form.Group className="mb-3 p-2" key="lastName">
          <Form.Control
            onChange={handleChangeInput}
            name="lastName"
            type="text"
            value={referral.lastName}
            required
            placeholder="Last Name"
          />
          {IsFormValid && referral.lastName === "" && (
            <Form.Text className="text-danger">Last name is required</Form.Text>
          )}
        </Form.Group>
      </div>

      <div className="col-6">
        <InputGroup className="mb-3 p-2">
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-country">
              {referral.countryCode || "+91"}
            </Dropdown.Toggle>
            <Dropdown.Menu className="country-code-dropdown-scroll">
              {COUNTRYCODES.map((country, index) => (
                <Dropdown.Item
                  className={
                    referral.countryCode === country.code ? "selected" : ""
                  }
                  key={index}
                  onClick={() => handleCountryChange(country.code)}
                >
                  {" "}
                  ({country.code})-{country.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Form.Control
            onChange={handleChangeInput}
            name="mobileNo"
            type="tel"
            inputMode="numeric"
            value={referral.mobileNo}
            required
            pattern="[0-9]{4,15}"
            placeholder="Mobile Number"
          />
        </InputGroup>
        <div style={{ marginTop: "-24px", marginLeft: "8px" }}>
          {IsFormValid && !referral.mobileNo && (
            <Form.Text className="text-danger">
              Mobile Number is required
            </Form.Text>
          )}
          {IsFormValid && referral.mobileNo && !IsMobileValid && (
            <Form.Text className="text-danger">
              Please enter a valid mobile number.
            </Form.Text>
          )}
        </div>
      </div>
      <div className="col-6">
        <Form.Group className="mb-3 p-2" key="emailId">
          <Form.Control
            onChange={handleChangeInput}
            name="emailId"
            type="email"
            value={referral.emailId}
            required
            placeholder="Email Id"
          />
          {IsFormValid && !referral.emailId && (
            <Form.Text className="text-danger">Email Id is required</Form.Text>
          )}
          {IsFormValid && referral.emailId && !IsEmailValid && (
            <Form.Text className="text-danger">
              Please enter a valid email address.
            </Form.Text>
          )}
        </Form.Group>
      </div>

      <div className="col-6">
        <Form.Group className="mb-3 p-2" key="userId">
          <Select
            options={referrals}
            onChange={handleAutoComplete}
            value={referral.referralIdTemp}
            isClearable={true}
            placeholder="Lead by"
          />
          {IsFormValid && !referral.userId && (
            <Form.Text className="text-danger">Lead by is required</Form.Text>
          )}
        </Form.Group>
      </div>

      <div className="col-6">
        <Form.Group className="mb-3 p-2" key="channelId">
          <Form.Select
            onChange={(e) => handleChangeSelect(e, "channelId")}
            name="channelId"
            value={referral.channelId}
            required
            aria-label="Referred Via"
          >
            <option value="">Lead Via</option>
            {referralChannelLov?.map((category: MasterValue, index) => (
              <option key={index} value={category.id}>
                {category.lovName}
              </option>
            ))}
          </Form.Select>
          {IsFormValid && referral.channelId === "" && (
            <Form.Text className="text-danger">Lead Via is required</Form.Text>
          )}
        </Form.Group>
      </div>
    </div>
  );
}

export default ReferralForm;
