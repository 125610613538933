import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import LoadingOverlay from "react-loading-overlay-ts";
import BounceLoader from "react-spinners/BounceLoader";
import styles from "./loader-indicator.module.css";
import { useAppSelector } from "../../redux/hooks/storeHooks";
import { QueryStatus } from "@reduxjs/toolkit/query";

const nonBlockingQuerySet: Set<string> = new Set([]);

function Overlay(props: any) {
  const queryRunning = useAppSelector((state) => {
    return Object.values(state.api.queries).some((query) => {
      return query && query.status === QueryStatus.pending;
    });
  });

  const mututaionRunning = useAppSelector((state) => {
    return Object.values(state.api.mutations).some((query) => {
      return query && query.status === QueryStatus.pending;
    });
  });

  const shouldBlock = queryRunning || mututaionRunning;

  // console.log(shouldBlock);

  return (
    <LoadingOverlay
      {...props}
      active={shouldBlock}
      spinner={<BounceLoader />}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 0.7)",
        }),
        content: (base) => ({
          ...base,
          marginTop: props.marginTop,
        }),
      }}
    >
      {props.children}
    </LoadingOverlay>
  );
}

export default Overlay;
