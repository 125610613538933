import { ToastContainer } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { removeToast } from "../../redux/slices/utilitySlice";

function Toasts() {
  const messages = useSelector((state: RootState) => state.utilities);
  const dispatch = useDispatch();
  return (
    <>
      <ToastContainer
        position="top-end"
        className="p-3 mt-5"
        style={{ zIndex: 1 }}
      >
        {messages.map((item, idx) => (
          <Toast
            onClose={() => dispatch(removeToast(item.id || 0))}
            bg={item.messageType}
            key={item.id}
            show={item.show}
            delay={item.delay}
            autohide
          >
            {item.headerText && (
              <Toast.Header>
                <strong className="me-auto">{item.headerText}</strong>
              </Toast.Header>
            )}
            {item.bodyText && <Toast.Body>{item.bodyText}</Toast.Body>}
          </Toast>
        ))}
      </ToastContainer>
    </>
  );
}

export default Toasts;
