import { DashboardSummary } from "../../components/common/model";
import { apiSlice } from "../slices/apiSlice";
import { fetchWithInterceptor } from "../utils/fetchWithInterceptor";

const commonConstants = {
    deviceUrl: 'device-details',
    deviceReadingUrl: 'device-readings',
    tagType: "Summary"
}

export async function getDashboardSummary() {
    const apiUrl = `${commonConstants.deviceUrl}/dashboard-summary`;
    const response = await fetchWithInterceptor(apiUrl);
    return response;
}

export async function getDeviceReadingSummary(deviceId: number, duration: string) {
    const apiUrl = `${commonConstants.deviceReadingUrl}/summary?deviceId=${deviceId}&duration=${duration}`;
    const response = await fetchWithInterceptor(apiUrl);
    return response;
}

export const dashboardSummaryApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDashboardSummary: builder.query<DashboardSummary, void>({
            query() {
                return {
                    url: `/${commonConstants.deviceUrl}/dashboard-summary`,
                };
            },
            providesTags: (result) =>
                result ? [{ ...result, type: "Summary", id: "LIST" }] : [{ type: "Summary", id: "LIST" }],
        })
    })
});

export const {
    useGetDashboardSummaryQuery
} = dashboardSummaryApiSlice;