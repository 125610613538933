import { format } from "date-fns/format";
import { useState } from "react";
import { useGetAllDevicesQuery } from "../../redux/apis/deviceApi";
import {
  useCreateOrderDetailMutation,
  useUpdateOrderDetailMutation,
} from "../../redux/apis/orderDetailApi";
import { useGetAllUserProfilesQuery } from "../../redux/apis/userProfileApi";
import { useAuth } from "../../redux/hooks/useAuth";
import CustomModal from "../common/custom-modal";
import { OrderDetail } from "../common/model";
import OrderDetailForm from "./order-detail-form";
import _ from "lodash";

function AddOrderDetail(props: {
  handleClose: (result: any) => void;
  order?: any;
  isReturnDate?: boolean;
}) {
  const user = useAuth();
  const { handleClose } = props;
  const [createFn] = useCreateOrderDetailMutation();
  const [updateFn] = useUpdateOrderDetailMutation();
  const [validated, setValidated] = useState(false);

  const users = useGetAllUserProfilesQuery().data;
  const alldevices = useGetAllDevicesQuery(user.account.user?.id || 0).data;
  const devices = _.filter(alldevices, (datum) => {
    return datum.statusCode === "AVAILABLE";
  });
  const data = props.order;
  const isManageDevice = !data?.id && !!data?.userId;

  let initialStateAttrValues = {
    id: data?.id || 0,
    orderId: data?.orderId || "",
    userId: data?.userId || 0,
    partnerId: data?.partnerId || user.account.user?.id || 0,
    deviceId: data?.deviceId || "",
    noOfFreeServices: data?.noOfFreeServices || 0,
    noOfAvailableServices: data?.noOfAvailableServices || 0,
    warrantyDurationMonth: data?.warrantyDurationMonth || 0,
    purchaseDate: data?.purchaseDate
      ? format(new Date(data?.purchaseDate), "yyyy-MM-dd")
      : "",
    warrantyExpireDate: data?.warrantyExpireDate
      ? format(new Date(data?.warrantyExpireDate), "yyyy-MM-dd")
      : "",
    returnDate: data?.returnDate
      ? format(new Date(data?.returnDate), "yyyy-MM-dd")
      : "",
    comments: data?.comments || "",
  };

  const [orderDetail, setOrderDetails] = useState({
    ...initialStateAttrValues,
  });

  const handleAutoComplete = (event: number, stateField: string) => {
    setOrderDetails({
      ...orderDetail,
      [stateField]: event,
    });
  };

  const handleChangeInput = (event: any) => {
    // default warrantyExpireDate one year from purchaseDate
    if (event.target.name === "purchaseDate") {
      const warrantyExpireDateCalc = new Date(event.target.value);
      warrantyExpireDateCalc.setFullYear(
        warrantyExpireDateCalc.getFullYear() + 1
      );
      orderDetail.warrantyExpireDate = format(
        new Date(warrantyExpireDateCalc),
        "yyyy-MM-dd"
      );
    }

    setOrderDetails({
      ...orderDetail,
      [event.target.name]: event.target.value,
    });
  };

  const handleSaveOrderDetail = async () => {
    const data = {
      ...orderDetail,
      purchaseDate: orderDetail?.purchaseDate
        ? new Date(orderDetail?.purchaseDate)
        : "",
      warrantyExpireDate: orderDetail?.warrantyExpireDate
        ? new Date(orderDetail?.warrantyExpireDate)
        : "",
      returnDate: orderDetail?.returnDate
        ? new Date(orderDetail?.returnDate)
        : null,
    } as OrderDetail;

    const result = !data.id
      ? await createFn(data).unwrap()
      : await updateFn(data).unwrap();
    result && handleClose(result);
  };

  const handleSubmit = () => {
    const isInValid = (
      ["userId", "deviceId", "purchaseDate", "warrantyExpireDate"] as const
    ).some((e) => !orderDetail[e]);

    if (isInValid) {
      setValidated(true);
      return;
    }

    handleSaveOrderDetail();
  };

  const popupTile = !!props.isReturnDate
    ? "Manage Return Date"
    : isManageDevice
    ? "Manage Machine"
    : "Order Detail";

  return (
    <CustomModal
      title={popupTile}
      size="lg"
      onClose={() => handleClose(null)}
      onSubmit={handleSubmit}
      submitBtn={!!orderDetail.id ? "Update" : "Save"}
      cancelBtn="Cancel"
    >
      <div className="d-flex flex-wrap justify-content-start">
        <OrderDetailForm
          users={users}
          devices={devices}
          orderDetail={orderDetail}
          validated={validated}
          isManageDevice={isManageDevice}
          isReturnDate={props.isReturnDate || false}
          handleChangeInput={handleChangeInput}
          handleAutoComplete={handleAutoComplete}
        />
      </div>
    </CustomModal>
  );
}

export default AddOrderDetail;
