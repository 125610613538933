import { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../redux/hooks/useAuth";
import { logout, setUser } from "../redux/slices/accountSlice";
import { apiSlice, useGetUserProfileQuery } from "../redux/slices/apiSlice";

function MainLayout() {
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useDispatch();

  const [skip, setSkip] = useState(true);

  const { data, isLoading } = useGetUserProfileQuery(undefined, { skip: skip });

  useEffect(() => {
    if (!auth.account.isAuthenticated) {
      navigate("/account/login");
    } else {
      setSkip(false);
      if (data != null) {
        dispatch(setUser(data));
      }
    }
  }, [auth.account.isAuthenticated, data]);

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand as={Link} to="/">
            AeroNero
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/users">
                Users
              </Nav.Link>
              <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Button
              onClick={() => {
                dispatch(logout());
                dispatch(apiSlice.util.resetApiState());
              }}
            >
              Logout
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {auth.account.user ? <Outlet /> : <div></div>}
    </>
  );
}

export default MainLayout;
