export const fetchWithInterceptor = async (url: any, options: any = {}, toJson: boolean = true) => {
  const appUrl = `/api/${url}`;
  const token = localStorage?.account
    ? JSON.parse(localStorage.account).accessToken
    : null;

  // Add a common header
  const headers = {
    ...options.headers,
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await fetch(appUrl, { ...options, headers });
    // Log the response status
    // console.log(`Response status: ${response.status}`);

    if (!response.ok) {
      const errorData = await response.json();
      return errorData;
      throw new Error(`Error: ${response.status} - ${errorData?.message || errorData?.errorMessage}`);
    }

    if (options.method === "DELETE" || !toJson) {
      return await response;
    } else {
      return await response.json();
    }
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
