import React, { useState } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  NewPassword,
  useCreateNewPasswordMutation
} from "../../redux/apis/forgotPasswordApi";
import { selectAccount } from "../../redux/slices/accountSlice";
import { ChangeEventType, FormEventType } from "../common/helper/helper";
import "../login/Login.scss";

const ResetPassword: React.FC = () => {
  const { email, otp } = useParams<{ email: string; otp: string }>(); // Accessing email and otp from route parameters

  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const [createNewPassword] = useCreateNewPasswordMutation();
  const account = useSelector(selectAccount);

  const handlePasswordChange = (e: ChangeEventType) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: ChangeEventType) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e: FormEventType) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    } else {
      setError("");
    }

    const data = {
      email: account.resetUserInfo?.email || "",
      resetCode: account.resetUserInfo?.code || "",
      newPassword: password,
    } as NewPassword;

    try {
      const result = await createNewPassword(data).unwrap();
      const toast = document.getElementById("passwordToast");
      if (toast) {
        toast.classList.add("show");
        setTimeout(() => {
          navigate("/account/login");
        }, 2000);
      }
    } catch (error) {}
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="auth-page">
      <Form onSubmit={handleSubmit}>
        <Stack gap={4} className="col-8 col-md-3 mx-auto">
          <div className="auth-title">Reset Password</div>
          <Form.Group controlId="password">
            {/* <Form.Label className="text-light">New Password</Form.Label> */}
            <div className="input-group">
              <Form.Control
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                autoComplete="off"
                size="lg"
                placeholder="New Password"
                required
              />
              <button
                className="btn btn-outline-light"
                type="button"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <EyeSlash /> : <Eye />}
              </button>
            </div>

            <Form.Text className="text-danger">
              {password.trim() === "" && "New password is required"}
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="confirmPassword">
            {/* <Form.Label className="text-light">Confirm Password</Form.Label> */}
            <div className="input-group">
              <Form.Control
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                autoComplete="off"
                size="lg"
                placeholder="Confirm Password"
                required
              />
              <button
                className="btn btn-outline-light"
                type="button"
                onClick={toggleConfirmPasswordVisibility}
              >
                {showConfirmPassword ? <EyeSlash /> : <Eye />}
              </button>
            </div>

            <Form.Text className="text-danger">
              {confirmPassword.trim() === "" && "Confirm password is required"}
            </Form.Text>

            {error && <Form.Text className="text-danger">{error}</Form.Text>}
          </Form.Group>
          <Button variant="primary" size="lg" type="submit">
            Submit
          </Button>
          <div
            id="passwordToast"
            className="toast align-items-center text-white bg-success position-fixed top-0 start-50 translate-middle-x"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="d-flex">
              <div className="toast-body">Password created successfully!</div>
            </div>
          </div>
        </Stack>
      </Form>
    </div>
  );
};

export default ResetPassword;
