import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import accountReducer from './slices/accountSlice';
import lovsReducer from './slices/lovSlice';
import utilityReducer from './slices/utilitySlice';
import { rtkQueryErrorLogger } from './utils/error-handler';
import { apiSlice } from './slices/apiSlice';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    account: accountReducer,
    lovs: lovsReducer,
    utilities: utilityReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      rtkQueryErrorLogger,
      apiSlice.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
