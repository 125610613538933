import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { getLovs } from "../../redux/apis/lovApi";
import { createReferral, updateReferral } from "../../redux/apis/referralApi";
import CustomModal from "../common/custom-modal";
import { MasterValue } from "../common/model";
import ReferralForm from "./referral-form";

function AddReferral(props: {
  handleClose: () => void;
  referral?: any;
  refreshTableData: () => void;
}) {
  const { handleClose, refreshTableData } = props;
  const [isFormValid, setIsFormValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isMobileValid, setIsMobileValid] = useState(false);
  const [statusLov, setStatusLov] = useState<MasterValue[]>([]);

  const data = props.referral;

  let initialStateAttrValues = {
    userId: data?.userId || 0,
    id: data?.id || 0,
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    emailId: data?.emailId || "",
    countryCode: data?.countryCode || "",
    mobileNo: data?.mobileNo || "",
    statusCode: data?.statusCode || "",
    redeemPoints: data?.redeemPoints || 0,
    isRedeemed: data?.isRedeemed || false,
    statusId: data?.statusId || "",
    channelId: data?.channelId || "",
    isActive: data?.isActive || false,
    referralIdTemp: data?.id
      ? {
          value: data.userProfile?.id || 0,
          label: data.userProfile?.firstName + "" + data.userProfile?.lastName,
        }
      : null,
  } as any;

  const [referralDetails, setReferralDetails] = useState({
    ...initialStateAttrValues,
  });

  useEffect(() => {
    const delayedSearch = debounce(async () => {
      try {
        const lovData = await getLovs();
        setStatusLov(lovData.filter((lo) => lo.listName === "ReferralStatus"));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }, 500);

    delayedSearch();
    return delayedSearch.cancel;
  }, []);

  const handleAutoComplete = (event: any) => {
    setReferralDetails({
      ...referralDetails,
      referralIdTemp: event,
      userId: event?.data?.id,
    });
  };
  const handleChangeSelect = (event: any, stateField: string) => {
    setReferralDetails({
      ...referralDetails,
      [stateField]: event.target.value,
    });
  };

  const handleCountryChange = (selectedCountryCode: string) => {
    setReferralDetails({
      ...referralDetails,
      countryCode: selectedCountryCode,
    });
  };

  const handleChangeInput = (event: any) => {
    if (event.target.name === "emailId") {
      const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
        event.target.value
      );
      setIsEmailValid(isEmailValid);
    } else if (event.target.name === "mobileNo") {
      const IsMobileValid = /^[0-9]{4,15}$/.test(event.target.value);
      setIsMobileValid(IsMobileValid);
    }

    setReferralDetails({
      ...referralDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handleValidation = () => {
    const isFirstNameValid = referralDetails.firstName.trim() !== "";
    const isLastNameValid = referralDetails.lastName.trim() !== "";
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
      referralDetails.emailId
    );
    const isMobileValid = /^[0-9]{4,15}$/.test(referralDetails.mobileNo);
    setIsMobileValid(isMobileValid);
    return isFirstNameValid && isLastNameValid && isEmailValid && isMobileValid;
  };

  const handleCreateNewReferral = async () => {
    if (!handleValidation()) {
      setIsFormValid(true);
      return;
    }

    const referral = {
      id: referralDetails.id || 0,
      userId: referralDetails.userId || referralDetails.referralIdTemp?.data.id,
      firstName: referralDetails.firstName || "",
      lastName: referralDetails.lastName || "",
      emailId: referralDetails.emailId || "",
      countryCode: referralDetails.countryCode || "",
      mobileNo: referralDetails.mobileNo || "",
      statusCode: referralDetails.statusCode || "",
      redeemPoints: referralDetails.redeemPoints || 0,
      isRedeemed: referralDetails.isRedeemed || true,
      channelId: referralDetails.channelId || "",
      isActive: referralDetails.isActive || true,
      userProfile: referralDetails.referralIdTemp?.data,
    } as any;

    referral.statusId = referral.statusId
      ? referral.statusId
      : statusLov?.find((se) => se.lovCode === "LEAD")?.id;

    const result = !referralDetails.id
      ? await createReferral(referral)
      : await updateReferral(referral);

    await refreshTableData();
    result && handleClose();
  };

  return (
    <CustomModal
      title="Lead"
      size="lg"
      onClose={handleClose}
      onSubmit={handleCreateNewReferral}
      submitBtn={!!referralDetails.id ? "Update" : "Save"}
      cancelBtn="Cancel"
    >
      <div className="d-flex flex-wrap justify-content-start">
        <ReferralForm
          referral={referralDetails}
          handleChangeInput={handleChangeInput}
          handleChangeSelect={handleChangeSelect}
          handleAutoComplete={handleAutoComplete}
          IsFormValid={isFormValid}
          IsEmailValid={isEmailValid}
          IsMobileValid={isMobileValid}
          handleCountryChange={handleCountryChange}
        />
      </div>
    </CustomModal>
  );
}

export default AddReferral;
