import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  emailRegex,
  setLocalStorageRecoverEmail,
} from "../common/helper/helper";
import { useAppDispatch } from "../../redux/hooks/storeHooks";
import { setResetPasswordUser } from "../../redux/slices/accountSlice";
import {
  useSendEmailForOTPMutation,
  ForgotPasswordRequest,
} from "../../redux/apis/forgotPasswordApi";
import { Button, Form, Stack } from "react-bootstrap";
import "../login/Login.scss";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .matches(emailRegex, "Invalid email format"),
});

const RecoverOtp: React.FC = () => {
  const [sendEmailFn, { isLoading }] = useSendEmailForOTPMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [email, setEmail] = useState<string>("");

  const onSubmitEmail = async (data: { email: string }) => {
    console.log("body", data);

    const forgetPassword = {
      email: email,
    } as ForgotPasswordRequest;

    try {
      const result = await sendEmailFn(forgetPassword).unwrap();
      dispatch(setResetPasswordUser({ email: forgetPassword.email }));
      setLocalStorageRecoverEmail(forgetPassword.email);
      navigate("/account/verify-otp");
    } catch (error) {}
  };

  return (
    <div className="auth-page">
      <Form onSubmit={handleSubmit(onSubmitEmail)}>
        <Stack gap={4} className="col-8 col-md-3 mx-auto">
          <div className="auth-title">Forgot Passeword</div>
          <Form.Group controlId="formBasicEmail">
            {/* <Form.Label>Email address</Form.Label> */}
            <Form.Control
              type="email"
              size="lg"
              {...register("email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              placeholder="Enter your Email Id"
              autoComplete="off"
            />
            {errors.email && (
              <Form.Text className="text-danger">
                {errors.email.message}
              </Form.Text>
            )}
          </Form.Group>

          <Button variant="primary" size="lg" type="submit">
            Send OTP
          </Button>
          <Link className="text-light text-center" to="/account/login">
            Login
          </Link>
        </Stack>
      </Form>
    </div>
  );
};

export default RecoverOtp;
