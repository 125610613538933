import {
  OrderDetail,
  OrderDetailSearch,
  OrderDetailSearchModel,
} from "../../components/common/model";
import { apiSlice } from "../slices/apiSlice";

const commonConstants = {
  urlSingularPrefix: "order-details",
  tagType: "OrderDetail",
};

export const orderDetailApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrderDetailById: builder.query<OrderDetail, number>({
      query(id) {
        return {
          url: `/${commonConstants.urlSingularPrefix}/${id}`,
          credentials: "include",
        };
      },
      providesTags: (result, error, id) => [{ type: "OrderDetail", id }],
    }),
    getOrderDetails: builder.query<OrderDetail[], number>({
      query(userId) {
        return {
          url: `/${commonConstants.urlSingularPrefix}?userId=${userId}`,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: `${"OrderDetail"}` as const,
                id,
              })),
              { type: "OrderDetail", id: "LIST" },
            ]
          : [{ type: "OrderDetail", id: "LIST" }],
    }),
    createOrderDetail: builder.mutation<OrderDetail, OrderDetail>({
      query(orderDetail) {
        return {
          url: `/${commonConstants.urlSingularPrefix}`,
          method: "POST",
          body: orderDetail,
        };
      },
      invalidatesTags: [{ type: "OrderDetail", id: "LIST" }],
    }),
    updateOrderDetail: builder.mutation<OrderDetail, OrderDetail>({
      query(orderDetail: OrderDetail) {
        return {
          url: `/${commonConstants.urlSingularPrefix}/${orderDetail.id}`,
          method: "PUT",
          body: orderDetail,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "OrderDetail", id },
              { type: "OrderDetail", id: "LIST" },
            ]
          : [{ type: "OrderDetail", id: "LIST" }],
    }),
    deleteOrderDetail: builder.mutation<OrderDetail, number>({
      query(id) {
        return {
          url: `/${commonConstants.urlSingularPrefix}/${id}`,
          method: "Delete",
        };
      },
      invalidatesTags: [{ type: "OrderDetail", id: "LIST" }],
    }),
    searchOrderDetails: builder.mutation<
      OrderDetailSearch[],
      OrderDetailSearchModel
    >({
      query(searchModel) {
        return {
          url: `/${commonConstants.urlSingularPrefix}/search`,
          method: "POST",
          body: searchModel,
        };
      },
      invalidatesTags: [{ type: "OrderDetail", id: "LIST" }],
    }),
  }),
});

export const {
  useGetOrderDetailByIdQuery,
  useGetOrderDetailsQuery,
  useCreateOrderDetailMutation,
  useUpdateOrderDetailMutation,
  useDeleteOrderDetailMutation,
  useSearchOrderDetailsMutation,
} = orderDetailApiSlice;
