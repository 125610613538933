import { useState } from "react";
import {
  useCreateDeviceMutation,
  useUpdateDeviceMutation
} from "../../redux/apis/deviceApi";
import { useAuth } from "../../redux/hooks/useAuth";
import { useLov } from "../../redux/hooks/useLov";
import CustomModal from "../common/custom-modal";
import { Device } from "../common/model";
import DeviceForm from "./device-form";

function AddDevice(props: {
  handleClose: () => void;
  device: any;
}) {
  const user = useAuth();
  const { filteredLovs } = useLov("DeviceStatus");
  const { handleClose } = props;
  const [createFn] = useCreateDeviceMutation();
  const [updateFn] = useUpdateDeviceMutation();
  const [isFormValid, setFormValid] = useState(false);
  const data = props.device;

  const initialStateAttrValues = {
    userId: data?.userId || 0,
    id: data?.id || 0,
    deviceId: data?.deviceId || "",
    deviceName: data?.deviceName || "",
    deviceFirmwareVersion: data?.deviceFirmwareVersion || "",
    timezone: data?.timezone || "",
    region: data?.region || "",
    location: data?.location || "",
    minEvaporatorCoilTemperatureThreshold:
      data?.minEvaporatorCoilTemperatureThreshold || 0,
    maxEvaporatorCoilTemperatureThreshold:
      data?.maxEvaporatorCoilTemperatureThreshold || 0,
    periodicDetailSendInterval: data?.periodicDetailSendInterval || 0,
    isActive: data?.isActive || true,
    statusId: data?.statusId || 0,
  };

  const [deviceDetails, setDeviceDetails] = useState({
    ...initialStateAttrValues,
  });

  const handleChangeSelect = (event: any, stateField: string) => {
    setDeviceDetails({
      ...deviceDetails,
      [stateField]: event.value,
    });
  };

  const handleChangeInput = (event: any) => {
    setDeviceDetails({
      ...deviceDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handleValidation = () => {
    const isDeviceIdValid = !!deviceDetails.deviceId;
    const isDeviceNameValid = !!deviceDetails.deviceName;
    return isDeviceIdValid && isDeviceNameValid;
  };

  const handleCreateNewDevice = async () => {
    if (!handleValidation()) {
      setFormValid(true);
      return;
    }

    const device = {
      id: deviceDetails.id,
      userId: user.account.user?.id,
      deviceId: deviceDetails.deviceId,
      deviceName: deviceDetails.deviceName,
      deviceFirmwareVersion: deviceDetails.deviceFirmwareVersion,
      timezone: deviceDetails.timezone,
      region: deviceDetails.region,
      location: deviceDetails.location,
      minEvaporatorCoilTemperatureThreshold:
        deviceDetails.minEvaporatorCoilTemperatureThreshold,
      maxEvaporatorCoilTemperatureThreshold:
        deviceDetails.maxEvaporatorCoilTemperatureThreshold,
      periodicDetailSendInterval: deviceDetails.periodicDetailSendInterval,
      isActive: deviceDetails.isActive,
    } as Device;
    device.statusId = device.statusId
      ? device.statusId
      : filteredLovs?.find((se) => se.lovCode === "AVAILABLE")?.id!;
    const result = !device.id
      ? await createFn(device).unwrap()
      : await updateFn(device).unwrap();
    result && handleClose();
  };

  return (
    <CustomModal
      title={(!deviceDetails.id ? "New" : "Update") + " Machine"}
      size="md"
      onClose={handleClose}
      onSubmit={handleCreateNewDevice}
      submitBtn={!!deviceDetails.id ? "Update" : "Save"}
      cancelBtn="Cancel"
    >
      <div className="d-flex flex-wrap justify-content-start">
        <DeviceForm
          device={deviceDetails}
          handleSelectUser={handleChangeSelect}
          handleChangeInput={handleChangeInput}
          isFormValid={isFormValid}
        />
      </div>
    </CustomModal>
  );
}

export default AddDevice;
