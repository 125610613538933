import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../store'

export interface LoginResponse {
    tokenType: string,
    accessToken: string,
    expiresIn: number,
    refreshToken: number
}

export interface LoginRequest {
    email: string
    password: string
}

export const apiSlice = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/',
        prepareHeaders: (headers, { getState }) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            const token = (getState() as RootState).account.accessToken
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        }
    }),
    refetchOnMountOrArgChange: 30,
    tagTypes: ['Profile', "Machines", "UserProfiles", "Devices", "OrderDetail", 'Summary'],
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, LoginRequest>({
            query: (credentials) => ({
                url: '/account/login',
                method: 'POST',
                body: credentials,
            }),
            invalidatesTags: ['Profile'],
        }),         
        getUserProfile: builder.query({
            query: () => ({
                url: '/account/profile',
                method: 'GET'
            }),
            providesTags: ['Profile', ],
        })
    }),
})

export const { useLoginMutation, useGetUserProfileQuery } = apiSlice
