type AppProps = {
  title: string;
  subTitle?: string;
};

function TitleInfo(props: AppProps) {
  return (
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <div>
        <h1 className="h4">{props.title}</h1>
        {!!props.subTitle && (
          <p className="text-secondary page-sub-title">{props.subTitle}</p>
        )}
      </div>
    </div>
  );
}

export default TitleInfo;
