import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectLov } from '../slices/lovSlice';

export const useLov = (listName?: string) => {
    const lovs = useSelector(selectLov);
    const filteredLovs = !!lovs?.length && !!listName ? lovs.filter((lov) => lov.listName.toLowerCase() === listName.toLowerCase()) : [];
        
    return useMemo(() => ({ lovs, filteredLovs }), [lovs]);
}
