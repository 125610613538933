import { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useUpdateUserProfileMutation } from "../../redux/apis/userProfileApi";
import {
  deleteProfilePicture,
  getProfilePicture,
  uploadProfilePicture,
} from "../../redux/apis/userProfilePictureApi";
import { useAuth } from "../../redux/hooks/useAuth";
import { addToast } from "../../redux/slices/utilitySlice";
import {
  convertBase64ToImgUrl,
  COUNTRYCODES,
  handleAddressFormat,
  ROLES,
} from "../common/helper/helper";
import TitleInfo from "../common/TitleInfo";
import "./user-profile.scss";

function UserProfile() {
  const { userInfo, isAdmin } = useAuth();
  const [updateFn] = useUpdateUserProfileMutation();

  const [validated, setValidated] = useState(false);

  const pnoSplit = userInfo?.phoneNumber
    ? userInfo?.phoneNumber.split("-")
    : "";

  const initialUserState = {
    id: userInfo?.id || 0,
    firstName: userInfo?.firstName || "",
    lastName: userInfo?.lastName || "",
    userCode: userInfo?.userCode || "",
    location: userInfo?.location || "",
    parentId: userInfo?.parentId || 0,
    parentName: userInfo?.parentName || "",
    email: userInfo?.email || "",
    phoneNumber: pnoSplit.length > 1 ? pnoSplit[1] : "",
    countryCode: pnoSplit.length > 1 ? pnoSplit[0] : "+91",
    password: userInfo?.password || "Aeronero@123",
    isDisabled: userInfo?.isDisabled || false,
    address1: userInfo?.address1 || "",
    address2: userInfo?.address2 || "",
    state: userInfo?.state || "",
    zipcode: userInfo?.zipcode || "",
    country: userInfo?.country || "",
    roles: !!userInfo?.roles.length ? userInfo?.roles[0] : "USER",
  };

  const [userInput, setUserInput] = useState(initialUserState);

  const defaultProfileUrl = require("../../images/user.png");
  const [profilePictureUrl, setProfilePictureUrl] = useState(null);
  const inputProfilePictureRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProfilePicture();
        const profileUrl = !!response
          ? convertBase64ToImgUrl(response)
          : response;

        setProfilePictureUrl(profileUrl);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (event: any, valueField: string) => {
    setUserInput((prevState) => ({
      ...prevState,
      [valueField]: event.target.value,
    }));
  };

  const handleSelect = (e: any) => {
    setUserInput((prevState) => ({
      ...prevState,
      roles: e.target.value,
    }));
  };

  const handleCountryChange = (selectedCountryCode: string) => {
    setUserInput((prevState) => ({
      ...prevState,
      countryCode: selectedCountryCode,
    }));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    handleSave();
  };

  const handleSave = async () => {
    try {
      const roles = [userInput.roles.toUpperCase()];
      const data: any = { ...userInput };
      data.roles = roles;
      data.phoneNumber = `${data.countryCode}-${data.phoneNumber}`;

      const result = await updateFn(data).unwrap();
    } catch {}
  };

  const handleProfileUpdate = () => {
    return (
      <Card>
        <Card.Header as="h5">Edit Profile</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-6">
                <Form.Group className="mb-3" controlId="validationCustom01">
                  <Form.Control
                    required
                    value={userInput.firstName}
                    type="text"
                    placeholder="First name"
                    onChange={(e) => handleInputChange(e, "firstName")}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter first name.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3" controlId="validationCustom02">
                  <Form.Control
                    required
                    value={userInput.lastName}
                    type="text"
                    placeholder="Last name"
                    onChange={(e) => handleInputChange(e, "lastName")}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter last name.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-6">
                <InputGroup className="mb-3" hasValidation>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="outline-secondary"
                      id="dropdown-country"
                    >
                      {userInput.countryCode || "+91"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="country-code-dropdown-scroll">
                      {COUNTRYCODES.map((country, index) => (
                        <Dropdown.Item
                          className={
                            userInput.countryCode === country.code
                              ? "selected"
                              : ""
                          }
                          key={index}
                          onClick={() => handleCountryChange(country.code)}
                        >
                          ({country.code})-{country.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Control
                    onChange={(e) => handleInputChange(e, "phoneNumber")}
                    name="mobileNo"
                    type="tel"
                    inputMode="numeric"
                    value={userInput.phoneNumber}
                    disabled={!isAdmin}
                    required
                    pattern="[0-9]{4,15}"
                    placeholder="Mobile Number"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid mobile number.
                  </Form.Control.Feedback>
                </InputGroup>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3" key="emailId">
                  <Form.Control
                    onChange={(e) => handleInputChange(e, "email")}
                    name="emailId"
                    type="email"
                    value={userInput.email}
                    disabled={!isAdmin}
                    required
                    pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                    placeholder="Email Id"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email address.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3" controlId="validationCustom04">
                  <Form.Select
                    required
                    value={userInput.roles}
                    onChange={handleSelect}
                    aria-label="Select roles"
                    disabled={!isAdmin}
                  >
                    {ROLES.filter((r) => (isAdmin ? true : r === "User")).map(
                      (role, index) => (
                        <option key={index} value={role}>
                          {role}
                        </option>
                      )
                    )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Choose valid role.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3" controlId="address1">
                  <Form.Control
                    value={userInput.address1}
                    type="text"
                    placeholder="Address 1"
                    onChange={(e) => handleInputChange(e, "address1")}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter address1.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3" controlId="address2">
                  <Form.Control
                    value={userInput.address2}
                    type="text"
                    placeholder="Address 2"
                    onChange={(e) => handleInputChange(e, "address2")}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter address2.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3" controlId="location">
                  <Form.Control
                    value={userInput.location}
                    type="text"
                    placeholder="City"
                    onChange={(e) => handleInputChange(e, "location")}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter location.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3" controlId="state">
                  <Form.Control
                    value={userInput.state}
                    type="text"
                    placeholder="State"
                    onChange={(e) => handleInputChange(e, "state")}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter state.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3" controlId="country">
                  <Form.Control
                    value={userInput.country}
                    type="text"
                    placeholder="Country"
                    onChange={(e) => handleInputChange(e, "country")}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter country.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-6">
                <Form.Group className="mb-3" controlId="zipcode">
                  <Form.Control
                    value={userInput.zipcode}
                    type="text"
                    placeholder="Zip Code"
                    onChange={(e) => handleInputChange(e, "zipcode")}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter zip code.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
            <div className="text-end">
              <Button type="submit" variant="primary">
                Update
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    );
  };

  const handleButtonClick = () => {
    // Trigger the input file click
    inputProfilePictureRef?.current?.click();
  };

  const toaster = (isSuccess: boolean, msg: string) => {
    dispatch(
      addToast({
        messageType: !!isSuccess ? "success" : "danger",
        bodyText: msg,
      })
    );
  };

  const handleProfilePictureDelete = async () => {
    try {
      if (
        window.confirm("Are you sure you want to delete this profile picture?")
      ) {
        const response = await deleteProfilePicture();
        toaster(
          !!response.ok,
          !!response.ok
            ? "Profile picture remove successfully"
            : "Failed to remove profile picture"
        );
        setProfilePictureUrl(null);
      }
    } catch {}
  };

  const handleProfilePictureRender = () => {
    return (
      <div className="custom-file-upload">
        <span className="profile-actions">
          <i
            title="Edit"
            className="bi bi-pencil-square"
            onClick={handleButtonClick}
          ></i>
          {!!profilePictureUrl && (
            <i
              title="Delete"
              className="bi bi-trash3-fill text-danger"
              onClick={handleProfilePictureDelete}
            ></i>
          )}
        </span>
        <img
          className="user-avator img-thumbnail"
          src={profilePictureUrl || defaultProfileUrl}
          alt="User Profile"
        />
        <input
          ref={inputProfilePictureRef}
          className="d-none"
          type="file"
          accept="image/png, image/jpeg"
          onChange={photoUpload}
        />
      </div>
    );
  };

  const photoUpload = (e: any) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    const MIN_FILE_SIZE = 1024; // 1 KB
    const MAX_FILE_SIZE = 512000; // 500 KB

    if (!file) {
      return;
    }

    const fileSizeBytes = file.size;
    if (fileSizeBytes < MIN_FILE_SIZE) {
      toaster(false, "Please upload a file larger than 1 KB.");
      return;
    }

    if (fileSizeBytes > MAX_FILE_SIZE) {
      toaster(false, "Please upload a file smaller than 500 KB.");
      return;
    }

    reader.onloadend = async () => {
      if (!!file) {
        const response = await uploadProfilePicture(file);
        const profileUrl = !!response
          ? convertBase64ToImgUrl(response.profilePicture)
          : response;

        toaster(
          !!profileUrl,
          !!profileUrl
            ? "Profile picture uploaded successfully"
            : "Upload Failed"
        );
        setProfilePictureUrl(profileUrl);
      } else {
        setProfilePictureUrl(defaultProfileUrl);
      }
    };

    if (!!file) {
      reader.readAsDataURL(file);
    }
  };

  const renderProfileInfo = () => {
    return (
      <Card className="text-center p-2">
        {handleProfilePictureRender()}
        <Card.Body>
          <Card.Title>
            {userInfo?.firstName}
            {userInfo?.lastName}
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {userInfo?.roles}
          </Card.Subtitle>
          <Card.Text>
            {!!userInfo && handleAddressFormat(userInfo)}
            <br />
            Email: {userInfo?.email}
            <br />
            Phone: {userInfo?.phoneNumber}
          </Card.Text>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <TitleInfo
        title={"User Profile"}
        subTitle={"Here is the details of user profile"}
      />

      <Row>
        <Col sm={8}>{handleProfileUpdate()}</Col>
        <Col sm={4}>{renderProfileInfo()}</Col>
      </Row>
    </>
  );
}

export default UserProfile;
