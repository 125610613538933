import { useEffect, useMemo, useState } from "react";
import { Button, Form, InputGroup, Stack, Table } from "react-bootstrap";
import {
  useDeleteUserMutation,
  useSearchUsersMutation,
} from "../../redux/apis/userProfileApi";
import { useAuth } from "../../redux/hooks/useAuth";
import { handleAddressFormat, ROLES } from "../common/helper/helper";
import { SearchModel, UserProfile } from "../common/model";

import _ from "lodash";
import CustomDropdown from "../common/custom-dropdown";
import TitleInfo from "../common/TitleInfo";
import AddUser from "./add-user";
import { useSearchParams } from "react-router-dom";

function UserList() {
  const user = useAuth();
  const [searchFn] = useSearchUsersMutation();
  const [deleteFn] = useDeleteUserMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userProfiles, setUserProfiles] = useState<any[]>();

  const [routeParams, setRouteParams] = useSearchParams();

  // Access the 'search' query parameter
  const roureQuery = routeParams.get("role");

  const [searchTerm, setSearchTerm] = useState({
    searchText: "",
    role: "",
    currentUserId: user.isAdmin ? 0 : user.account.user?.id || 0,
    id: 0,
  } as SearchModel);

  const handleSearch = (e: any) => {
    const searchText = e.target.value;
    searchTerm.searchText = searchText;
    setSearchTerm(searchTerm);
    handleFetch();
  };

  const debouncedResults = useMemo(() => {
    return _.debounce(handleSearch, 300);
  }, []);

  useEffect(() => {
    const delayedSearch = _.debounce(async () => {
      searchTerm.role = roureQuery || "";
      await handleFetch();
    }, 200);

    delayedSearch();

    return () => {
      delayedSearch.cancel();
      debouncedResults.cancel();
    };
  }, []);

  const handleFetch = async () => {
    const result: any = await searchFn(searchTerm).unwrap();
    setUserProfiles(result);
  };

  const handleUserFormDialog = (item: any) => {
    setIsOpen(true);
    setSelectedUser(item);
  };

  const onDelete = async (id: number) => {
    try {
      if (window.confirm("Are you sure you want to delete this item?")) {
        const result = await deleteFn(id).unwrap();
        handleFetch();
      }
    } catch {}
  };

  const handleClose = (result: any) => {
    if (result) {
      handleFetch();
    }

    setIsOpen(false);
  };

  const renderHeader = () => {
    return (
      <thead>
        <tr>
          <th>User Code</th>
          <th>User Name</th>
          <th>Email Address</th>
          <th>Phone Number</th>
          <th className="w-25">Address</th>
          <th>Role</th>
          {/* <th>Status</th> */}
          <th style={{ width: "95px" }}>Action</th>
        </tr>
      </thead>
    );
  };

  const renderTableDataRow = (item: UserProfile, index: number) => {
    const uniqueKey = `user-${item.userId}-${index}`;
    // const statusClass = item.isDisabled
    //   ? "btn btn-sm btn-secondary"
    //   : "btn btn-sm btn-success";
    // const status = item.isDisabled ? "Activate" : "Deactivate";
    return (
      <tr key={uniqueKey}>
        <td>{item.userCode}</td>
        <td>
          {item.firstName} {item.lastName}
        </td>
        <td>{item.email}</td>
        <td>{item.countryCode && (<span>{item.countryCode}-</span>)}{item.phoneNumber}</td>
        <td>{handleAddressFormat(item)}</td>
        <td>{item.roles?.join(",")}</td>
        {/* <td>
          <button className={statusClass} onClick={() => onUpdate(item)}>
            {status}
          </button>
        </td> */}
        <td>
          <button
            className="btn btn-link py-0 px-1"
            onClick={() => handleUserFormDialog(item)}
          >
            <i className="bi bi-pencil-square"></i>
          </button>
          <button
            className="btn btn-link py-0 px-1"
            onClick={() => onDelete(item.id)}
          >
            <i className="bi bi-trash3-fill text-danger"></i>
          </button>
        </td>
      </tr>
    );
  };

  const renderTableRows = () => {
    return userProfiles?.map((item, index) => {
      return renderTableDataRow(item, index);
    });
  };

  return (
    <>
      <TitleInfo
        title={"Users"}
        subTitle={"Here is the list of all the user"}
      />

      {/* Filter & Action */}
      <Stack
        direction="horizontal"
        gap={3}
        className="justify-content-end mb-2"
      >
        <div className="flex-fill"></div>
        <InputGroup size="sm" className="w-50">
          <InputGroup.Text id="search-addon1">
            <i className="bi bi-search"></i>
          </InputGroup.Text>
          <Form.Control
            placeholder="Search user name or email or mobile no"
            aria-label="Search user name or email or mobile no"
            aria-describedby="search-addon1"
            onChange={debouncedResults}
          />
        </InputGroup>
        {user.isAdmin && (
          <CustomDropdown
            title={searchTerm.role || "Role"}
            options={ROLES.filter((r) => r !== "User")
              .concat("All")
              .reverse()}
            onSelect={(val: any) => {
              searchTerm.role = val === "All" ? "" : val;
              setSearchTerm(searchTerm);
              handleFetch();
            }}
          />
        )}
        <div className="vr" />
        <Button className="btn-sm" onClick={() => handleUserFormDialog(null)}>
          Add User
        </Button>
      </Stack>

      {isOpen && (
        <AddUser
          handleClose={handleClose}
          isOpenPopup={isOpen}
          user={selectedUser}
        />
      )}
      <Table responsive hover size="sm" className="custom-table">
        {renderHeader()}
        <tbody>{renderTableRows()}</tbody>
      </Table>
    </>
  );
}

export default UserList;
