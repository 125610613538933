import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../redux/hooks/useAuth";

function AccountLayout() {
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (auth.account.isAuthenticated) {
      navigate("/");
    }
  }, [auth.account.isAuthenticated]);

  return (
    <>
      <Outlet />
    </>
  );
}

export default AccountLayout;
