import { fetchWithInterceptor } from "../utils/fetchWithInterceptor";

export interface NotificationMessageDetail {
  messageTypeId: number;
  id: number;
  sendType: string;
  name: string;
  messageType: string;
}

const routeUrl = "notification-config";

export const getNotificationConfig = async (): Promise<
  NotificationMessageDetail[]
> => {
  const response = await fetchWithInterceptor(routeUrl);
  return response;
};

export const createNotificationConfig = async (
  data: NotificationMessageDetail
): Promise<NotificationMessageDetail> => {
  const response = await fetchWithInterceptor(routeUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response;
};

export const updateNotificationConfig = async (
  id: number,
  data: NotificationMessageDetail
): Promise<NotificationMessageDetail> => {
  const response = await fetchWithInterceptor(`${routeUrl}/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response;
};

export const deleteNotificationConfig = async (
  id: number
): Promise<NotificationMessageDetail> => {
  const response = await fetchWithInterceptor(`${routeUrl}/${id}`, {
    method: "DELETE",
  });
  return response;
};
