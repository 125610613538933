import { useEffect, useState } from "react";
import { getLovs } from "../../redux/apis/lovApi";
import {
  useCreateServiceRequestMutation,
  useUpdateServiceRequestMutation
} from "../../redux/apis/serviceRequestApi";
import { useAuth } from "../../redux/hooks/useAuth";
import CustomModal from "../common/custom-modal";
import { MasterValue } from "../common/model";
import ServiceRequestForm from "./service-request-form";

function AddServiceRequest(props: {
  handleClose: () => void;
  device?: any;
  refreshTableData: any;
}) {
  const user = useAuth();
  // const { data, isLoading } = useGetDeviceQuery(props.device?.id);
  const { handleClose } = props;
  const [createFn] = useCreateServiceRequestMutation();
  const [updateFn] = useUpdateServiceRequestMutation();
  const [isFormValid, setFormValid] = useState(false);
  const [statusLov, setStatusLov] = useState<MasterValue[]>([]);

  const data = props.device;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const lovData = await getLovs();
        setStatusLov(lovData.filter((lo) => lo.listName === "ServiceStatus"));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  let initialStateAttrValues = {
    userId: data?.userId || 0,
    id: data?.id || 0,
    orderId: data?.orderId || "",
    userProfileId: data?.userProfile?.id || "",
    firstName: data?.userProfile?.firstName || "",
    lastName: data?.userProfile?.lastName || "",
    customerCode: data?.userProfile?.customerCode || "",
    phoneNumber: data?.userProfile?.phoneNumber || "",
    email: data?.userProfile?.email || "",
    deviceId: data?.deviceDetail?.id || "",
    deviceStrId: data?.deviceDetail?.deviceId || "",
    deviceName: data?.deviceDetail?.deviceName || "",
    statusCode: data?.statusCode || "",
    serviceDescription: data?.serviceDescription || "",
    categoryId: data?.categoryId || 0,
    serviceTypeId: data?.serviceTypeId || 0,
    deviceIdTemp: !data?.id
      ? null
      : {
          value: data.orderDetail?.orderId || 0,
          label: data.orderDetail?.orderId || "",
        },

    resolvedDate: data?.resolvedDate || null,
    statusId: data?.statusId || "",
    comments: data?.comments || "",
    requestedDate: data?.requestedDate ? new Date(data.requestedDate) : "",
    ticketId: data?.ticketId || "",
  };

  const [serviceRequestDetails, setServiceRequestDetails] = useState({
    ...initialStateAttrValues,
  });

  const handleChangeSelect = (event: any, stateField: string) => {
    setServiceRequestDetails({
      ...serviceRequestDetails,
      [stateField]: event.target.value,
    });
  };

  const handleChangeInput = (event: any) => {
    setServiceRequestDetails({
      ...serviceRequestDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handleAutoComplete = (event: any) => {
    setServiceRequestDetails({
      ...serviceRequestDetails,
      deviceIdTemp: event,
      orderId: event?.data?.orderId || "",
      deviceId: event?.data?.deviceId || "",
      deviceStrId: event?.data?.deviceDetail?.deviceId || "",
      deviceName: event?.data?.deviceDetail?.deviceName || "",
      userProfileId: event?.data?.userProfile?.id || "",
      firstName: event?.data?.userProfile?.firstName || "",
      lastName: event?.data?.userProfile?.lastName || "",
      customerCode: event?.data?.userProfile?.userCode || "",
      email: event?.data?.userProfile?.email || "",
      phoneNumber: event?.data?.userProfile?.phoneNumber || "",
    });
  };

  const handleValidation = () => {
    const isDeviceIdValid = serviceRequestDetails.deviceId;
    const isServiceTypeIdValid = !!serviceRequestDetails.serviceTypeId;
    const isCategoryIdValid = !!serviceRequestDetails.categoryId;
    return isDeviceIdValid && isCategoryIdValid && isServiceTypeIdValid;
  };

  const handleCreateServiceRequest = async () => {
    if (!handleValidation()) {
      setFormValid(true);
      return;
    }

    const serviceRequest = {
      ...serviceRequestDetails,
    } as any;

    serviceRequest.userId = user.account.user?.id;
    serviceRequest.requestedDate = serviceRequestDetails.id
      ? serviceRequest.requestedDate
      : new Date();
    serviceRequest.statusId = serviceRequest.statusId
      ? serviceRequest.statusId
      : statusLov?.find((se) => se.lovCode === "REQUESTED")?.id;

    const result = !serviceRequestDetails.id
      ? await createFn(serviceRequest).unwrap()
      : await updateFn(serviceRequest).unwrap();
    props.refreshTableData() && handleClose();
  };

  return (
    <CustomModal
      title="Service Request Form"
      size="lg"
      onClose={handleClose}
      onSubmit={handleCreateServiceRequest}
      submitBtn={!!serviceRequestDetails.id ? "Update" : "Save"}
      cancelBtn="Cancel"
    >
      <div className="d-flex flex-wrap justify-content-start">
        <ServiceRequestForm
          serviceRequest={serviceRequestDetails}
          handleSelectUser={handleChangeSelect}
          handleChangeInput={handleChangeInput}
          handleAutoComplete={handleAutoComplete}
          handleChangeSelect={handleChangeSelect}
          isFormValid={isFormValid}
        />
      </div>
    </CustomModal>
  );
}

export default AddServiceRequest;
