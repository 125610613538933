import { UserProfile } from "../../components/common/model";
import { apiSlice } from "../slices/apiSlice";

const commonConstants = {
  urlSingularPrefix: "service-details",
  urlPluralPrefix: "service-details",
  tagType: "Devices",
};

export interface ServiceRequestModel {
  userId: number;
  id: number;
  deviceId: number;
  categoryId: number;
  serviceDescription: string;
  statusCode: string;
  comments: string;
  resolvedDate: Date;
  category: string;
  deviceStrId: string;
  deviceName: string;
  orderId: string;
  typeCode: string;
  soldDate: Date;
  noOfFreeServices: number;
  noOfAvailableServices: number;
  warrantyDurationMonth: number;
  amcavailability: boolean;
  amcrenewedDate: Date;
  warrantyExpireDate: Date;
  userProfile: UserProfile;
  ticketId: string;
  serviceTypeId: string;
  noOfDaystoWarrantyExpires?: number;
  noOfDaystoPurchased?: number;
}

export interface searchDevices {
  searchText: string;
  role: string;
  currentUserId: number;
}

export const deviceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServiceRequest: builder.query<ServiceRequestModel, number>({
      query(id) {
        return {
          url: `/${commonConstants.urlPluralPrefix}/${id}`,
          credentials: "include",
        };
      },
      providesTags: (result, error, id) => [{ type: "Devices", id }],
    }),
    getAllServiceRequest: builder.query<ServiceRequestModel[], number>({
      query(userId) {
        return {
          url: `/${commonConstants.urlPluralPrefix}?userId=${userId}`,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: `${"Devices"}` as const,
                id,
              })),
              { type: "Devices", id: "LIST" },
            ]
          : [{ type: "Devices", id: "LIST" }],
    }),
    createServiceRequest: builder.mutation<
      ServiceRequestModel,
      ServiceRequestModel
    >({
      query(serviceRequest) {
        return {
          url: `/${commonConstants.urlPluralPrefix}`,
          method: "POST",
          body: serviceRequest,
        };
      },
      invalidatesTags: [{ type: "Devices", id: "LIST" }],
    }),
    updateServiceRequest: builder.mutation<
      ServiceRequestModel,
      ServiceRequestModel
    >({
      query(device: ServiceRequestModel) {
        return {
          url: `/${commonConstants.urlSingularPrefix}/${device.id}`,
          method: "PUT",
          body: device,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Devices", id },
              { type: "Devices", id: "LIST" },
            ]
          : [{ type: "Devices", id: "LIST" }],
    }),
    deleteServiceRequest: builder.mutation<ServiceRequestModel, number>({
      query(id) {
        return {
          url: `/${commonConstants.urlPluralPrefix}/${id}`,
          method: "Delete",
        };
      },
      invalidatesTags: [{ type: "Devices", id: "LIST" }],
    }),
    searchServiceRequest: builder.mutation<
      ServiceRequestModel[],
      searchDevices
    >({
      query(serviceRequest) {
        return {
          url: `/${commonConstants.urlPluralPrefix}/search`,
          method: "POST",
          body: serviceRequest,
        };
      },
      invalidatesTags: [{ type: "Devices", id: "LIST" }],
    }),
  }),
});

export const {
  useCreateServiceRequestMutation,
  useDeleteServiceRequestMutation,
  useUpdateServiceRequestMutation,
  useGetAllServiceRequestQuery,
  useGetServiceRequestQuery,
  useSearchServiceRequestMutation,
} = deviceApiSlice;
