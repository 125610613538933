import { format } from "date-fns/format";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { getLovs } from "../../redux/apis/lovApi";
import { useUpdateServiceRequestMutation } from "../../redux/apis/serviceRequestApi";
import { MasterValue } from "../common/model";

function UpdateStatus(props: {
  handleClose: () => void;
  isOpenPopup: boolean;
  serviceRequest: any;
  refreshTableData: any;
}) {
  const [updateFn] = useUpdateServiceRequestMutation();
  const [validated, setValidated] = useState(false);
  const [isOpen, setIsOpen] = useState(props.isOpenPopup || false);
  const serviceRequestData = props.serviceRequest;
  const initialUserState = {
    statusId: serviceRequestData.statusId,
    comments: serviceRequestData.comments,
    resolvedDate: (serviceRequestData.resolvedDate
      ? format(new Date(serviceRequestData?.resolvedDate), "yyyy-MM-dd")
      : "") as any,
  };

  const [userInput, setUserInput] = useState(initialUserState);

  const [statusLov, setStatusLov] = useState<MasterValue[]>([]);
  const [showResolvedDate, setResolvedDate] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const lovData = await getLovs();
        const statusLovData = lovData.filter(
          (lo) => lo.listName === "ServiceStatus" && lo.lovCode !== "REQUESTED"
        );
        setStatusLov(statusLovData);
        if (statusLovData.length && serviceRequestData.statusId) {
          const isCompleted =
            statusLovData?.find((sl) => sl.id === +serviceRequestData.statusId)
              ?.lovCode === "COMPLETED";
          setResolvedDate(isCompleted);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [serviceRequestData.statusId]);

  const handleSave = async () => {
    try {
      const statusData = {
        ...serviceRequestData,
        statusId: userInput.statusId,
        comments: userInput.comments,
        resolvedDate: userInput.resolvedDate || null,
      } as any;

      await updateFn(statusData).unwrap();
      props.refreshTableData() && closeModal();
    } catch {}
  };

  const closeModal = () => {
    setIsOpen(false);
    setUserInput(initialUserState);
    setValidated(false);
    props.handleClose();
  };

  const handleInputChange = (event: any, valueField: string) => {
    setUserInput((prevState) => ({
      ...prevState,
      [valueField]: event.target.value,
    }));
  };

  const handleSelect = (e: any) => {
    setUserInput((prevState) => ({
      ...prevState,
      statusId: e.target.value,
    }));
    showResolvedField(+e.target.value);
  };

  const showResolvedField = (id: number) => {
    if (statusLov?.find((sl) => sl.id === +id)?.lovCode === "COMPLETED") {
      setResolvedDate(true);
    } else {
      setResolvedDate(false);
      setUserInput((prevState) => ({
        ...prevState,
        resolvedDate: null,
      }));
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    handleSave();
  };

  return (
    <div key={"user-modal-1"}>
      {isOpen && (
        <Modal
          show={isOpen}
          key={"user-modal-2"}
          centered
          onHide={closeModal}
          backdrop="static"
          keyboard={false}
        >
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Update Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-12">
                  <Form.Group className="mb-3" controlId="status">
                    <Form.Select
                      required
                      value={userInput.statusId}
                      onChange={handleSelect}
                      aria-label="Select Status"
                    >
                      <option value="">Status</option>
                      {statusLov?.map((category: MasterValue, index) => (
                        <option key={index} value={category.id}>
                          {category.lovName}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Choose Status.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-12">
                  <Form.Group className="mb-3" controlId="comments">
                    <Form.Control
                      required
                      value={userInput.comments}
                      type="text"
                      placeholder="Description"
                      onChange={(e) => handleInputChange(e, "comments")}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter description.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                {showResolvedDate && (
                  <div className="col-12">
                    <Form.Group className="mb-3" controlId="resolvedDate">
                      <Form.Control
                        required
                        value={userInput.resolvedDate}
                        type="date"
                        placeholder="resolvedDate"
                        onChange={(e) => handleInputChange(e, "resolvedDate")}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter resolved date.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                )}
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" type="submit">
                Save
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </div>
  );
}

export default UpdateStatus;
