import { UserProfile } from "../../components/common/model";
import { fetchWithInterceptor } from "../utils/fetchWithInterceptor";

export interface Referral {
  userId: number;
  id: number;
  firstName: string;
  lastName: string;
  emailId: string;
  countryCode: string;
  mobileNo: string;
  status?: string;
  redeemPoints: number;
  isRedeemed: boolean;
  isActive: boolean;
  channelId?: number;
  statusId?: number;
  userProfile?: UserProfile;
  channel?: string;
  lookingFor?: string;
  buyPeriod?: string;
}

export interface CreateReferralRequest {
  firstName: string;
  lastName: string;
  emailId: string;
  countryCode: string;
  mobileNo: string;
  status?: string;
  statusId?: number;
}

export interface UpdateReferralRequest {
  id: number;
  firstName?: string;
  lastName?: string;
  emailId?: string;
  countryCode?: string;
  mobileNo?: string;
  statusCode?: string;
  redeemPoints?: number;
  isRedeemed?: boolean;
  reason?: string;
  isActive?: boolean;
}

const BASE_URL = "/api";

export const getReferral = async (accessToken: any): Promise<Referral[]> => {
  const response = await fetch(`${BASE_URL}/Referral`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (!response.ok) {
    throw new Error("Failed to fetch referrals");
  }
  return response.json();
};

export const createReferral = async (
  data: CreateReferralRequest
): Promise<Referral> => {
  const routeUrl = "/Referral";
  const response = await fetchWithInterceptor(routeUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response;
};

export const createReferralByCode = async (
  code: string,
  data: CreateReferralRequest
): Promise<Referral> => {
  const routeUrl = "referral/code/" + code;
  const response = await fetchWithInterceptor(routeUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response;
};

export const getReferralBySearch = async (
  accessToken: any,
  data?: any
): Promise<any[]> => {
  const response = await fetch(`${BASE_URL}/referral/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error("Failed to get orderDetail");
  }
  return response.json();
};

export const updateReferral = async (
  data: UpdateReferralRequest
): Promise<Referral> => {
  const routeUrl = `/Referral/${data.id}`;
  const response = await fetchWithInterceptor(routeUrl, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return response;
};

export const deleteReferral = async (
  accessToken: any,
  id: number
): Promise<void> => {
  const routeUrl = `/Referral/${id}`;
  const response = await fetchWithInterceptor(routeUrl, {
    method: "DELETE",
  });
  return response;
};
