import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAppDispatch } from "../../redux/hooks/storeHooks";
import { setAccount } from "../../redux/slices/accountSlice";
import { SubmitHandler, useForm } from "react-hook-form";
import { LoginRequest, useLoginMutation } from "../../redux/slices/apiSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Alert, Stack } from "react-bootstrap";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./Login.scss";

const schema = yup
  .object({
    email: yup
      .string()
      .required("Email required")
      .email("Please enter valid email"),
    password: yup.string().required("Password required"),
  })
  .required();

function Login() {
  const dispatch = useAppDispatch();
  const [loginFn, { isLoading }] = useLoginMutation();
  const [showError, setShowError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "", //"senpark15@gmail.com",
      password:"" // "Admin@123",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<LoginRequest> = async (loginInfo) => {
    try {
      setShowError(false);
      const result = await loginFn(loginInfo).unwrap();
      if (result) {
        dispatch(setAccount(result));
      }
    } catch {
      setShowError(true);
    }
  };

  return (
    <div className="auth-page">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={4} className="col-8 col-md-3 mx-auto">
          {showError && (
            <Alert variant="danger">Invalid Username or Password</Alert>
          )}
          <div className="auth-title">
            Welcome to <span> AERONERO! </span>
          </div>
          <Form.Group controlId="formBasicEmail">
            {/* <Form.Label>Email address</Form.Label> */}
            <Form.Control
              type="email"
              size="lg"
              {...register("email")}
              placeholder="Email or Mobile Number"
            />
            {errors.email && (
              <Form.Text className="text-danger">
                {errors.email.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            {/* <Form.Label>Password</Form.Label> */}
            <Form.Control
              type="password"
              size="lg"
              {...register("password")}
              placeholder="Enter your password"
            />
            {errors.password && (
              <Form.Text className="text-danger">
                {errors.password.message}
              </Form.Text>
            )}
          </Form.Group>

          <Button variant="primary" size="lg" type="submit">
            Login
          </Button>
          <Link className="text-light text-center" to="/account/recover-otp">
            Forgot Password?
          </Link>
        </Stack>
      </Form>
    </div>
  );
}

export default Login;
