import { Button, Form, InputGroup, Stack, Table } from "react-bootstrap";

import { format } from "date-fns";
import _ from "lodash";
import { debounce } from "lodash";

import { useEffect, useMemo, useState } from "react";
import {
  useDeleteOrderDetailMutation,
  useSearchOrderDetailsMutation,
} from "../../redux/apis/orderDetailApi";
import CustomDropdown from "../common/custom-dropdown";
import { ROLES } from "../common/helper/helper";
import { SearchModel } from "../common/model";
import TitleInfo from "../common/TitleInfo";
import AddOrderDetail from "./add-order-detail";
import { useAuth } from "../../redux/hooks/useAuth";
import { Link } from "react-router-dom";

function OrderDetailsList() {
  const user = useAuth();
  const [showNewOrderDetailForm, setShowOrderDetailForm] = useState(false);
  const [isReturnDateForm, setIsReturnDateForm] = useState(false);
  const [selectedOrderDetail, setSelectedOrderDetail] = useState(null);
  const [searchTerm, setSearchTerm] = useState({
    searchText: "",
    role: "",
    currentUserId: user.account.user?.id || 0,
    id: 0,
  } as SearchModel);
  const [orderDetails, setOrderDetails] = useState<any[]>();
  const [searchFn] = useSearchOrderDetailsMutation();
  const [deleteFn] = useDeleteOrderDetailMutation();

  const handleSearch = (e: any) => {
    const searchText = e.target.value;
    searchTerm.searchText = searchText;
    setSearchTerm(searchTerm);
    handleFetch();
  };

  const debouncedResults = useMemo(() => {
    return _.debounce(handleSearch, 300);
  }, []);

  useEffect(() => {
    const delayedSearch = debounce(async () => {
      await handleFetch();
    }, 200);

    delayedSearch();

    return () => {
      delayedSearch.cancel();
      debouncedResults.cancel();
    };
  }, []);

  const handleFetch = async () => {
    const result: any = await searchFn(searchTerm).unwrap();
    setOrderDetails(result);
  };

  const handleClickEdit = (item: any, isManageDevice = false) => {
    setShowOrderDetailForm(true);
    const selectedOrderDetail = isManageDevice
      ? { userId: item.userId }
      : { ...item };
    setSelectedOrderDetail(selectedOrderDetail);
  };

  const onDelete = async (id: number) => {
    try {
      if (window.confirm("Are you sure you want to delete this item?")) {
        const result = await deleteFn(id).unwrap();
        handleFetch();
      }
    } catch {}
  };

  const handleClose = (result: any) => {
    setShowOrderDetailForm(false);
    setIsReturnDateForm(false);

    if (result?.id) {
      handleFetch();
    }
  };

  const renderHeader = () => {
    return (
      <thead>
        <tr>
          <th>Partner</th>
          <th>Order Id</th>
          <th>User Code</th>
          <th>User Name</th>
          <th>Phone Number</th>
          <th>Email Address</th>
          <th>Machine Id</th>
          <th>Machine Name</th>
          <th>Purchase Date</th>
          <th>AMC/Warranty</th>
          <th>Return Date</th>
          <th style={{ width: "150px", minWidth: "150px" }}></th>
        </tr>
      </thead>
    );
  };

  const renderTableDataRow = (item: any, index: number) => {
    const uniqueKey = `user-${item.id}-${index}`;
    return (
      <tr key={uniqueKey}>
        <td>{item.partnerName}</td>
        <td>{item.orderId}</td>
        <td>{item.userProfile.userCode}</td>
        <td>
          {item.userProfile.firstName}&nbsp;
          {item.userProfile.lastName}
        </td>
        <td>{item.userProfile.phoneNumber}</td>
        <td>{item.userProfile.email}</td>
        <td>
          <Link to={`/dashboard?machineId=${item.deviceDetail.deviceId}`}>{item.deviceDetail.deviceId}</Link>
        </td>
        <td>{item.deviceDetail.deviceName}</td>
        <td>{item.purchaseDate && format(item.purchaseDate, "dd/MM/yyyy")}</td>
        <td>
          {item.warrantyExpireDate &&
            format(item.warrantyExpireDate, "dd/MM/yyyy")}
        </td>
        <td>{item.returnDate && format(item.returnDate, "dd/MM/yyyy")}</td>
        <td>
          <button
            title="Manage Return Date"
            className="btn btn-link py-0 px-1"
            onClick={() => {
              setIsReturnDateForm(true);
              handleClickEdit(item);
            }}
          >
            <i className="bi bi-bootstrap-reboot"></i>
          </button>
          <button
            title="Manage Machine"
            className="btn btn-link py-0 px-2"
            onClick={() => handleClickEdit(item, true)}
          >
            <i className="bi bi-card-checklist"></i>
          </button>
          <button
            title="Edit"
            className="btn py-0 px-2"
            onClick={() => handleClickEdit(item)}
          >
            <i className="bi bi-pencil-square"></i>
          </button>
          <button
            title="Delete"
            className="btn btn-link py-0 px-1"
            onClick={() => onDelete(item.id)}
          >
            <i className="bi bi-trash3-fill text-danger"></i>
          </button>
        </td>
      </tr>
    );
  };

  const renderTableRows = () => {
    return orderDetails?.map((item, index) => {
      return renderTableDataRow(item, index);
    });
  };

  return (
    <>
      <TitleInfo
        title={"Order Details"}
        subTitle={"Here is the list of all the order details"}
      />

      {/* Filter & Action */}
      <Stack
        direction="horizontal"
        gap={3}
        className="justify-content-end mb-2"
      >
        <div className="flex-fill"></div>
        <InputGroup size="sm" className="w-50">
          <InputGroup.Text id="search-addon1">
            <i className="bi bi-search"></i>
          </InputGroup.Text>
          <Form.Control
            placeholder="Search user name or email or mobile no"
            aria-label="Search user name or email or mobile no"
            aria-describedby="search-addon1"
            onChange={debouncedResults}
          />
        </InputGroup>
        {user.isAdmin && (
          <CustomDropdown
            title={searchTerm.role || "Role"}
            options={ROLES.filter((r) => r !== "User")
              .concat("All")
              .reverse()}
            onSelect={(val) => {
              searchTerm.role = val === "All" ? "" : val;
              setSearchTerm(searchTerm);
              handleFetch();
            }}
          />
        )}
        <div className="vr" />
        <Button
          className="btn-sm"
          onClick={() => {
            setSelectedOrderDetail(null);
            setShowOrderDetailForm(true);
          }}
        >
          Add Order Detail
        </Button>
      </Stack>

      {showNewOrderDetailForm && (
        <AddOrderDetail
          handleClose={handleClose}
          order={selectedOrderDetail}
          isReturnDate={isReturnDateForm}
        />
      )}
      <Table responsive hover size="sm" className="custom-table">
        {renderHeader()}
        <tbody>{renderTableRows()}</tbody>
      </Table>
    </>
  );
}

export default OrderDetailsList;
