import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { getLovs } from "../../redux/apis/lovApi";
import {
  NotificationMessageDetail,
  createNotificationConfig,
  updateNotificationConfig,
} from "../../redux/apis/notificationConfigApi";
import { MasterValue } from "../common/model";

function NotificationConfiguration(props: {
  handleClose: () => void;
  isOpenPopup: boolean;
  selectedConfig: NotificationMessageDetail;
  refreshTableData: () => void;
}) {
  const initialUserState = {
    id: props.selectedConfig?.id || 0,
    messageTypeId: props.selectedConfig?.messageTypeId || 0,
    sendType: props.selectedConfig?.sendType || "",
    name: props.selectedConfig?.name || "",
    messageType: props.selectedConfig?.messageType || "",
  };

  const [validated, setValidated] = useState(false);
  const [isOpen, setIsOpen] = useState(props.isOpenPopup || false);
  const [userInput, setUserInput] = useState(initialUserState);
  const [notificationTypeLov, setNotificationTypeLov] = useState<MasterValue[]>(
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const lovData = await getLovs();
        setNotificationTypeLov(
          lovData.filter((lo) => lo.listName === "NotificationType")
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const sendViaOptions = [
    { value: "SMS", label: "SMS" },
    { value: "WHATSAPP", label: "WhatsApp" },
    { value: "EMAIL", label: "Email" },
  ];

  const handleSave = async () => {
    try {
      const result = !userInput.id
        ? await createNotificationConfig(userInput)
        : await updateNotificationConfig(userInput.id, userInput);
      await props.refreshTableData();
      result && closeModal();
    } catch {}
  };

  const closeModal = () => {
    setIsOpen(false);
    setUserInput(initialUserState);
    setValidated(false);
    props.handleClose();
  };

  const handleInputChange = (event: any, valueField: string) => {
    setUserInput((prevState) => ({
      ...prevState,
      [valueField]: event.target.value,
    }));
  };

  const handleSelect = (e: any, valueField: string) => {
    setUserInput((prevState) => ({
      ...prevState,
      [valueField]: e.target.value,
    }));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    handleSave();
  };

  return (
    <div key={"user-modal-1"}>
      {isOpen && (
        <Modal
          show={isOpen}
          key={"user-modal-2"}
          centered
          onHide={closeModal}
          backdrop="static"
          keyboard={false}
        >
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Notification Configuration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-12">
                  <Form.Group className="mb-3 p-2" key="typeId">
                    <Form.Select
                      name="messageTypeId"
                      value={userInput.messageTypeId}
                      onChange={(e) => handleSelect(e, "messageTypeId")}
                      required
                      aria-label="Message Type"
                    >
                      <option value="">Choose Message Type</option>
                      {notificationTypeLov?.map(
                        (category: MasterValue, index) => (
                          <option key={index} value={category.id}>
                            {category.lovName}
                          </option>
                        )
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Choose Message Type.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-12">
                  <Form.Group className="mb-3 p-2" key="sendType">
                    <Form.Select
                      value={userInput.sendType}
                      onChange={(e) => handleSelect(e, "sendType")}
                      required
                    >
                      <option value="">Choose Send Type</option>
                      {sendViaOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Choose Send Type.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-12">
                  <Form.Group className="mb-3 p-2" controlId="comments">
                    <Form.Control
                      value={userInput.name}
                      as="textarea"
                      rows={2}
                      placeholder="Comments"
                      onChange={(e) => handleInputChange(e, "name")}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter comments.
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" type="submit">
                Save
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </div>
  );
}

export default NotificationConfiguration;
