import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectAccount } from '../slices/accountSlice';

export const useAuth = () => {
    const account = useSelector(selectAccount);
    const userInfo = account && account.user;
    const isAdmin = account && account.user?.roles.includes("Admin");
    const accessToken = account?.accessToken;

    return useMemo(() => ({ account, userInfo, isAdmin, accessToken }), [account]);
}
