import { fetchWithInterceptor } from "../utils/fetchWithInterceptor";

const routeUrl = "device-readings";

export const uploadDeviceReadings = async (
    file: any
): Promise<any> => {

    const formData = new FormData();
    formData.append("formFile", file); // Assuming your API expects a field named "profilePicture"

    const response = await fetchWithInterceptor(`${routeUrl}/excel-upload`, {
        method: "POST",
        headers: {
            Accept: '*/*',
        },
        body: formData,
    }, false);
    return response;
};

export const createDeviceReadings = async (deviceReadings: any[]) => {
    const response = await fetchWithInterceptor(routeUrl, {
        method: "POST",
        body: JSON.stringify(deviceReadings),
        headers: {
            "content-type": "application/json"
        }
    }, true);

    return response;
}
