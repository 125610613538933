import { ChangeEvent, Dispatch, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import CustomModal from "../common/custom-modal";
import { Device } from "../common/model";
import { createDeviceReadings } from "../../redux/apis/deviceReadingsApi";

interface AddDeviceReadingProps {
  handleClose: Dispatch<void>
  devices: Device[]
}

interface DeviceReading {
  humidity?: string;
  temperature?: string;
  readingDateTime?: string;
  device?: Device;
}

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

export default function AddDeviceReading(props: AddDeviceReadingProps) {
  const [isFormInvalid, setFormInvalid] = useState(false);
  const [isMachineTypeInvalid, setIsMachineTypeInvalid] = useState(false);
  const [deviceReading, setDeviceReading] = useState<DeviceReading>();

  const handleFieldChange = (field: string, value: any) => {
    setDeviceReading({ ...deviceReading, [field]: value });
  }

  const handleDeviceChange = (device?: any) => {
    handleFieldChange("device", device);
    validateMachineType(device);
  }

  const handleChange = (event: ChangeEvent<FormControlElement>) => {
    handleFieldChange(event.target.name, event.target.value);
  }

  const getMachineType = (device?: Device) => {
    return (device ?? deviceReading?.device)?.deviceName?.replace(/ /g, '')?.toUpperCase() || "";
  }

  const validateMachineType = (device?: Device) => {
    const isMachineTypeInvalid = !Object.keys(MACHINE_CAPACITY).includes(getMachineType(device));

    setIsMachineTypeInvalid(isMachineTypeInvalid);

    return isMachineTypeInvalid;
  }

  const validateForm = () => {
    let isInvalid = false;

    if (!deviceReading?.temperature) {
      isInvalid = true;
    }

    if (!deviceReading?.humidity) {
      isInvalid = true;
    }

    if (!deviceReading?.device) {
      isInvalid = true;
    }

    if (!deviceReading?.readingDateTime) {
      isInvalid = true;
    }

    if (validateMachineType()) {
      isInvalid = true;
    }

    setFormInvalid(isInvalid);

    return isInvalid;
  }

  const handleSave = async () => {
    if (validateForm()) {
      return;
    }

    const temperature = parseFloat(deviceReading?.temperature || "");
    const humidity = parseFloat(deviceReading?.humidity || "");
    const waterOutput = getWaterOutputPerDay(temperature, humidity, getMachineType() as any);

    const readings = [{
      deviceId: deviceReading?.device?.deviceId,
      ambientTemperature: temperature,
      ambientHumidity: humidity,
      waterDispensedOverLifetime: waterOutput,
      waterGeneratedOverLifetime: waterOutput,
      readingDateTime: moment(deviceReading?.readingDateTime).toJSON()
    }];

    const response = await createDeviceReadings(readings);

    if (response.status == 200) {
      props.handleClose();
    }
  }

  return (
    <CustomModal
      title="Add Machine Reading"
      size="sm"
      submitBtn="Save"
      cancelBtn="Cancel"
      onClose={props.handleClose}
      onSubmit={handleSave}
    >
      <div>
        <Form.Group className="mb-3">
          <Form.Label>Machine</Form.Label>
          <Form.Control
            type="hidden"
            isInvalid={isFormInvalid && isMachineTypeInvalid}
            name="device"
          />
          <Select
            className="custom-atocomplete"
            options={props.devices}
            onChange={handleDeviceChange}
            value={deviceReading?.device as any}
            isClearable={true}
            placeholder="Search machine"
            getOptionLabel={(option: Device) => {
              const label = !!option.deviceId
                ? `${option.deviceId} (${option.deviceName})`
                : option.deviceId;
              return label;
            }}
            getOptionValue={(option: Device) => option.deviceId}
            styles={customStyles}
          />
          <Form.Control.Feedback type="invalid">
            {!deviceReading?.device && (
              <span>
                Please select machine.
              </span>
            )}
            {deviceReading?.device && (
              <span>
                Unknown machine {deviceReading.device.deviceName}.
              </span>
            )}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Reading date time</Form.Label>
          <Form.Control
            onChange={handleChange}
            required
            isInvalid={isFormInvalid && !deviceReading?.readingDateTime}
            name="readingDateTime"
            type="datetime-local"
            value={deviceReading?.readingDateTime}
            placeholder="Reading date time"
          />
          <Form.Control.Feedback type="invalid">
            Please enter reading date time.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Dry-bulb temperature T °C</Form.Label>
          <Form.Control
            onChange={handleChange}
            required
            isInvalid={isFormInvalid && !deviceReading?.temperature}
            name="temperature"
            type="number"
            value={deviceReading?.temperature}
            placeholder="Temperature"
          />
          <Form.Control.Feedback type="invalid">
            Please enter temperature.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Relative Humidity RH %</Form.Label>
          <Form.Control
            onChange={handleChange}
            required
            isInvalid={isFormInvalid && !deviceReading?.humidity}
            name="humidity"
            type="number"
            value={deviceReading?.humidity}
            placeholder="Humidity"
          />
          <Form.Control.Feedback type="invalid">
            Please enter humidity.
          </Form.Control.Feedback>
        </Form.Group>
      </div>
    </CustomModal>
  )
}

const ATMOSPHERIC_PRESSURE = 101325;
const MACHINE_CAPACITY = {
  NERO: 19.36,
  BUBBLE: 38.72,
  DRIZZLE: 67.75,
  THUNDER: 290.35,
  AIRWELL: 967.85,
}

function getHumidityRatio(temperature: number, humidity: number) {
  const saturationVaporPressure = 610.94 * Math.exp(17.625 * temperature / (temperature + 243.04));
  const waterVaporPartialPressure = humidity / 100 * saturationVaporPressure;
  const dryAirPartialPressure = ATMOSPHERIC_PRESSURE - waterVaporPartialPressure;
  const humidityRatio = (0.62198 * waterVaporPartialPressure / dryAirPartialPressure) * 1000;
  return humidityRatio;
}

function getWaterOutputPerDay(temperature: number, humidity: number, machine: keyof typeof MACHINE_CAPACITY) {
  const humidityRatio = getHumidityRatio(temperature, humidity);
  const output = ((humidityRatio * MACHINE_CAPACITY[machine]) / 1000) * 24;
  return Math.round((output + Number.EPSILON) * 100) / 100;
}

const customStyles = {
  control: (base: any) => ({
    ...base,
    height: 58,
    minHeight: 58,
  }),
};
