import { FloatingLabel, Form } from "react-bootstrap";

function CustomInput(props: any) {
  const { label, controlId, errorMsg } = props;

  return (
    <FloatingLabel
      controlId={controlId || "floatingInput"}
      label={label}
      className="mb-3"
    >
      <Form.Control {...props} />
      <Form.Control.Feedback type="invalid">
        {errorMsg || "Input field is required"}
      </Form.Control.Feedback>
    </FloatingLabel>
  );
}

export default CustomInput;
