import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { getLovs } from "../../redux/apis/lovApi";
import { useSearchOrderDetailsMutation } from "../../redux/apis/orderDetailApi";
import { useAuth } from "../../redux/hooks/useAuth";
import { MasterValue } from "../common/model";

function ServiceRequestForm(props: {
  serviceRequest: any;
  handleChangeInput: any;
  handleSelectUser: any;
  handleAutoComplete: any;
  handleChangeSelect: any;
  isFormValid: boolean;
}) {
  const {
    serviceRequest,
    handleChangeInput,
    handleAutoComplete,
    handleChangeSelect,
    isFormValid,
  } = props;
  const user = useAuth();
  const [orderId, setOrderIdData] = useState([]);
  const [serviceTypLov, setServiceTypeLov] = useState<MasterValue[]>([]);
  const [serviceCategoryLov, setServiceCategoryLov] = useState<MasterValue[]>(
    []
  );
  const [searchOrderDetailsFn] = useSearchOrderDetailsMutation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orderSearch = {
          searchText: "",
          role: "",
          currentUserId: user?.account?.user?.id!,
          id: 0,
        };
        const orderData = await searchOrderDetailsFn(orderSearch).unwrap();
        const deviceData = orderData?.map((item: any) => ({
          value: item.orderId,
          label: item.orderId,
          data: item,
        }));
        setOrderIdData(deviceData as any);
        const lovData = await getLovs();
        setServiceTypeLov(
          lovData.filter((lo) => lo.listName === "ServiceType")
        );
        setServiceCategoryLov(
          lovData.filter((lo) => lo.listName === "ServiceCategory")
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="row">
      <div className="col-6">
        <Form.Group className="mb-3 p-2" key="orderId">
          <Select
            options={orderId}
            onChange={handleAutoComplete}
            value={serviceRequest.deviceIdTemp}
            isClearable={true}
            placeholder="Search Order Id"
            isDisabled={serviceRequest.id}
          />
          {isFormValid && !serviceRequest.deviceId && (
            <Form.Text className="text-danger">
              Please select valid Order Id
            </Form.Text>
          )}
        </Form.Group>
      </div>
      <div className="col-6">
        <Form.Group className="mb-3 p-2" key="customerCode">
          <Form.Control
            onChange={handleChangeInput}
            name="customerCode"
            type="text"
            value={serviceRequest.customerCode}
            placeholder="Customer Code"
            disabled
          />
        </Form.Group>
      </div>
      <div className="col-6">
        <Form.Group className="mb-3 p-2" key="firstName">
          <Form.Control
            onChange={handleChangeInput}
            name="firstName"
            type="text"
            value={serviceRequest.firstName}
            placeholder="First Name"
            disabled
          />
        </Form.Group>
      </div>
      <div className="col-6">
        <Form.Group className="mb-3 p-2" key="lastName">
          <Form.Control
            onChange={handleChangeInput}
            name="lastName"
            type="text"
            value={serviceRequest.lastName}
            placeholder="Last Name"
            disabled
          />
        </Form.Group>
      </div>
      <div className="col-6">
        <Form.Group className="mb-3 p-2" key="phoneNumber">
          <Form.Control
            onChange={handleChangeInput}
            name="phoneNumber"
            type="text"
            value={serviceRequest.phoneNumber}
            placeholder="Mobile Number"
            disabled
          />
        </Form.Group>
      </div>
      <div className="col-6">
        <Form.Group className="mb-3 p-2" key="email">
          <Form.Control
            onChange={handleChangeInput}
            name="email"
            type="email"
            value={serviceRequest.email}
            placeholder="Email Id"
            disabled
          />
        </Form.Group>
      </div>
      <div className="col-6">
        <Form.Group className="mb-3 p-2" key="deviceStrId">
          <Form.Control
            onChange={handleChangeInput}
            name="deviceStrId"
            type="text"
            value={serviceRequest.deviceStrId}
            placeholder="Machine Id"
            disabled
          />
        </Form.Group>
      </div>
      <div className="col-6">
        <Form.Group className="mb-3 p-2" key="deviceName">
          <Form.Control
            onChange={handleChangeInput}
            name="deviceName"
            type="text"
            value={serviceRequest.deviceName}
            placeholder="Machine Name"
            disabled
          />
        </Form.Group>
      </div>
      <div className="col-6">
        <Form.Group className="mb-3 p-2" key="serviceType">
          <Form.Select
            required
            value={serviceRequest.serviceTypeId}
            onChange={(e) => handleChangeSelect(e, "serviceTypeId")}
            aria-label="Select ServiceType"
          >
            <option value="">Service Type</option>
            {serviceTypLov?.map((category: MasterValue, index) => (
              <option key={index} value={category.id}>
                {category.lovName}
              </option>
            ))}
          </Form.Select>
          {isFormValid && !serviceRequest.serviceTypeId && (
            <Form.Text className="text-danger">
              Service type is required
            </Form.Text>
          )}
        </Form.Group>
      </div>
      <div className="col-6">
        <Form.Group className="mb-3 p-2" key="categoryId">
          <Form.Select
            required
            value={serviceRequest.categoryId}
            onChange={(e) => handleChangeSelect(e, "categoryId")}
            aria-label="Select Category"
          >
            <option value="">Category</option>
            {serviceCategoryLov?.map((category: MasterValue, index) => (
              <option key={index} value={category.id}>
                {category.lovName}
              </option>
            ))}
          </Form.Select>
          {isFormValid && !serviceRequest.categoryId && (
            <Form.Text className="text-danger">Category is required</Form.Text>
          )}
        </Form.Group>
      </div>
      <div className="col-12">
        <Form.Group className="mb-3 p-2" key="serviceDescription">
          <Form.Control
            onChange={handleChangeInput}
            name="serviceDescription"
            type="text"
            value={serviceRequest.serviceDescription}
            placeholder="Message / Description"
          />
        </Form.Group>
      </div>
    </div>
  );
}

export default ServiceRequestForm;
