import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ToastMessage {
    messageType?: string,
    bodyText?: string,
    headerText?: string,
    show?: boolean,
    delay?: number,
    id?: number
}

const initialState: ToastMessage[] = [];

export const utilitySlice = createSlice({
    name: 'utility',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

        addToast: (
            state,
            message: PayloadAction<ToastMessage>
        ) => {
            const item = { id: Math.random() * 100000, show: true, delay: 10000, messageType: "primary", ...message.payload }
            console.log("toast", item);
            state.push(item);
        },
        removeToast: (state, id: PayloadAction<number>) => {
            const item = state.find(p => p.id === id.payload);
            if (item) {
                state.splice(state.indexOf(item), 1);
            }
        }
    },

});

export const { addToast, removeToast } = utilitySlice.actions;

export default utilitySlice.reducer;