import { useEffect, useRef, useState } from "react";
import {
  Button,
  Container,
  Nav,
  Navbar,
  Offcanvas,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { convertBase64ToImgUrl } from "../components/common/helper/helper";
import { useGetLovsQuery } from "../redux/apis/lovApi";
import { getProfilePicture } from "../redux/apis/userProfilePictureApi";
import { useAuth } from "../redux/hooks/useAuth";
import { logout, setUser } from "../redux/slices/accountSlice";
import { apiSlice, useGetUserProfileQuery } from "../redux/slices/apiSlice";
import { setLov } from "../redux/slices/lovSlice";
import { ReactComponent as HeaderLogSvg } from "./../images/aeroneroLogo.svg";
import "./LayoutSection.scss"; // You can create this file for custom styles if needed
import SideBarMenus from "./sidebar-menus";

function LayoutSection() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [skip, setSkip] = useState(true);
  const [isToggle, setToggle] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const { data, isLoading } = useGetUserProfileQuery(undefined, { skip: skip });
  const lovs = useGetLovsQuery().data;
  const defaultProfileUrl = require("./../images/user.png");
  const [profilePictureUrl, setProfilePictureUrl] = useState(defaultProfileUrl);

  useEffect(() => {
    if (!auth.account.isAuthenticated) {
      navigate("/account/login");
    } else {
      setSkip(false);
      if (data != null) {
        dispatch(setUser(data));
      }
    }
  }, [auth.account.isAuthenticated, data]);

  useEffect(() => {
    if (auth.account.isAuthenticated && lovs) {
      dispatch(setLov(lovs));
    }
  }, [auth.account.isAuthenticated, lovs]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProfilePicture();
        const profileUrl = !!response
          ? convertBase64ToImgUrl(response)
          : defaultProfileUrl;

        setProfilePictureUrl(profileUrl);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleToggle = () => {
    setToggle(!isToggle);
    return;
  };

  const onLogout = () => {
    try {
      dispatch(logout());
      dispatch(apiSlice.util.resetApiState());
    } catch (error) {}
  };

  const target: any = useRef();

  useEffect(() => {
    // event listeners for the escape key and outside click
    const handleEscapeAndOutsideClick = (e: any) => {
      if (
        e.key === "Escape" ||
        (target.current && !target.current.contains(e.target))
      ) {
        setShowProfile(false);
      }
    };

    document.addEventListener("keydown", handleEscapeAndOutsideClick);
    document.addEventListener("mousedown", handleEscapeAndOutsideClick);

    return () => {
      document.removeEventListener("keydown", handleEscapeAndOutsideClick);
      document.removeEventListener("mousedown", handleEscapeAndOutsideClick);
    };
  }, []);

  const renderHeader = () => {
    const userInfo = auth.account.user;

    return (
      <Navbar
        sticky="top"
        expand="lg"
        className="app-header navbar navbar-expand-lg shadow-sm"
        onToggle={handleToggle}
      >
        <Container fluid>
          <Navbar.Brand className="col-md-3 col-lg-2 text-center p-0" href="/">
            <HeaderLogSvg className="header-logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbarLabel-expand" />
          {/* smail device sidemenu */}
          <Offcanvas
            show={isToggle}
            onHide={handleToggle}
            id={`offcanvasNavbar-expand`}
            aria-labelledby={`offcanvasNavbarLabel-expand`}
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title
                className="flex-fill text-center"
                id={`offcanvasNavbarLabel-expand`}
              >
                <HeaderLogSvg className="header-logo" />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <SideBarMenus handleMenuSelect={handleToggle} />
              <div className="mt-4">{renderPopOverMenus(true)}</div>
            </Offcanvas.Body>
          </Offcanvas>

          {/* user profile */}
          <Nav className="ms-auto" id="userProfile">
            <OverlayTrigger
              trigger="click"
              key="test"
              placement="bottom"
              show={showProfile}
              rootClose={true}
              rootCloseEvent="mousedown"
              overlay={
                <Popover id={`popover-positioned-bottom`}>
                  <Popover.Header as="h3">
                    <div>
                      {userInfo?.firstName} {userInfo?.lastName}
                    </div>
                    <div className="text-muted">{userInfo?.email}</div>
                  </Popover.Header>
                  <Popover.Body className="px-0">
                    {renderPopOverMenus()}
                  </Popover.Body>
                </Popover>
              }
            >
              <button
                ref={target}
                className="btn border-0 user-profile"
                onClick={() => {
                  setShowProfile(!showProfile);
                }}
              >
                <span className="user-name">
                  {userInfo?.firstName} {userInfo?.lastName}
                </span>
                <img
                  src={profilePictureUrl || defaultProfileUrl}
                  className="rounded-circle"
                  alt="profile"
                />
                <i className="bi bi-caret-down-fill text-primary"></i>
              </button>
            </OverlayTrigger>
          </Nav>
        </Container>
      </Navbar>
    );
  };

  const renderPopOverMenus = (isMiniSideMenu = false) => {
    return (
      <div className="d-grid">
        <Button
          type="button"
          className="text-start text-decoration-none px-3"
          variant="link"
          onClick={() => {
            if (isMiniSideMenu) {
              handleToggle();
            } else {
              setShowProfile(false);
            }

            navigate("/user-profile");
          }}
        >
          <i className="bi bi-person-circle me-2"></i>
          <span className="text-dark">Profile</span>
        </Button>
        <Button
          type="button"
          className="text-start text-decoration-none px-3"
          variant="link"
          onClick={() => {
            if (isMiniSideMenu) {
              handleToggle();
            } else {
              setShowProfile(false);
            }

            navigate("/settings");
          }}
        >
          <i className="bi bi-gear me-2"></i>
          <span className="text-dark">Settings</span>
        </Button>
        <Button
          type="button"
          className="text-start text-decoration-none px-3"
          variant="link"
          onClick={() => {
            if (isMiniSideMenu) {
              handleToggle();
            } else {
              setShowProfile(false);
            }

            onLogout();
          }}
        >
          <i className="bi bi-box-arrow-right me-2"></i>
          <span className="text-dark">Logout</span>
        </Button>
      </div>
    );
  };

  const renderSidebarSection = () => {
    return (
      <div className="container-fluid page-body-wrapper">
        <nav id="sidebar" className="sidebar d-md-block col-md-3 col-lg-2">
          <div className="sidebar-sticky">
            <SideBarMenus />
          </div>
        </nav>
        <main
          role="main"
          className="main-panel col ml-sm-auto col-lg-10 px-2 px-md-4"
        >
          <div>{auth.account.user ? <Outlet /> : <div></div>}</div>
        </main>
      </div>
    );
  };

  return (
    <div className="">
      {renderHeader()}
      {renderSidebarSection()}
    </div>
  );
}

export default LayoutSection;
