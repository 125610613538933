import { Device } from '../../components/common/model';
import { apiSlice } from '../slices/apiSlice';

const commonConstants = {
  urlSingularPrefix: 'device-details',
  urlPluralPrefix: 'device-details',
  tagType: "Devices"
}

export const deviceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDevice: builder.query<Device, number>({
      query(id) {
        return {
          url: `/${commonConstants.urlPluralPrefix}/${id}`,
          credentials: "include",
        };
      },
      providesTags: (result, error, id) => [{ type: "Devices", id }],
    }),
    getAllDevices: builder.query<Device[], number>({
      query(userId) {
        return {
          url: `/${commonConstants.urlPluralPrefix}?userId=${userId}`,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: `${"Devices"}` as const,
                id,
              })),
              { type: "Devices", id: "LIST" },
            ]
          : [{ type: "Devices", id: "LIST" }],
    }),
    searchDevices: builder.query<Device[], string>({
      query(searchtext) {
        return {
          url: `/${commonConstants.urlPluralPrefix}/searchtext?searchtext=${searchtext}`,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: `${"Devices"}` as const,
                id,
              })),
              { type: "Devices", id: "LIST" },
            ]
          : [{ type: "Devices", id: "LIST" }],
    }),
    createDevice: builder.mutation<Device, Device>({
      query(device) {
        return {
          url: `/${commonConstants.urlPluralPrefix}`,
          method: "POST",
          body: device,
        };
      },
      invalidatesTags: [{ type: "Devices", id: "LIST" }],
    }),
    updateDevice: builder.mutation<Device, Device>({
      query(device: Device) {
        return {
          url: `/${commonConstants.urlSingularPrefix}/${device.id}`,
          method: "PUT",
          body: device,
        };
      },
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Devices", id },
              { type: "Devices", id: "LIST" },
            ]
          : [{ type: "Devices", id: "LIST" }],
    }),
    deleteDevice: builder.mutation<Device, number>({
      query(id) {
        return {
          url: `/${commonConstants.urlPluralPrefix}/${id}`,
          method: "Delete",
        };
      },
      invalidatesTags: [{ type: "Devices", id: "LIST" }],
    }),
  }),
});

export const {
    useCreateDeviceMutation,
    useDeleteDeviceMutation,
    useUpdateDeviceMutation,
    useGetAllDevicesQuery,
    useSearchDevicesQuery,
    useGetDeviceQuery
} = deviceApiSlice;

