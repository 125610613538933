import { format } from "date-fns";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Stack, Table } from "react-bootstrap";
import { useSearchOrderDetailsMutation } from "../../redux/apis/orderDetailApi";
import {
  ServiceRequestModel,
  searchDevices,
} from "../../redux/apis/serviceRequestApi";
import TitleInfo from "../common/TitleInfo";
import SendNotification from "./service-notification-form";
import { OrderDetailSearchModel } from "../common/model";

function ServiceNotificationList() {
  const [searchResult] = useSearchOrderDetailsMutation();
  const [serviceRequestList, setServiceRequestList] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [selectedItemData, setSelectedItemData] = useState<any[]>([]);
  const [sendNotification, setSendNotification] = useState(false);

  useEffect(() => {
    const delayedSearch = debounce(async () => {
      await fetchServiceNotification();
    }, 300); // Adjust delay as needed

    delayedSearch();
    return delayedSearch.cancel;
  }, []);

  const durationFilterData = [
    { value: "7", label: "Expire within 7 Days" },
    { value: "14", label: "Expire within 14 Days" },
    { value: "30", label: "Expire within 30 Days" },
    { value: "60", label: "Expire within 60 Days" },
    { value: "90", label: "Expire within 90 Days" },
    { value: "180", label: "Expire within 180 Days" },
    { value: "270", label: "Expire within 270 Days" },
    { value: "360", label: "Expire within 360 Days" },
  ];

  const renderHeader = () => {
    return (
      <thead>
        <tr>
          <th>
            <Form.Check
              type="checkbox"
              checked={
                selectedItemData.length > 0 &&
                selectedItemData.length === serviceRequestList.length
              }
              onChange={handleSelectAll}
            />
          </th>
          <th>Order Id</th>
          <th>Name</th>
          <th>Email Address</th>
          <th>Phone No.</th>
          <th>Machine Id</th>
          <th>Purchase Date</th>
          <th>Warranty Expire Date</th>
          <th>Expire Within</th>
          <th>Message</th>
        </tr>
      </thead>
    );
  };

  const renderTableDataRow = (item: any, index: number) => {
    const uniqueKey = `user-${item.id}-${index}`;
    return (
      <tr key={uniqueKey}>
        <td>
          <Form.Check
            type="checkbox"
            checked={selectedItems.includes(item.id)}
            onChange={() => handleSelectRow(item.id, item)}
          />
        </td>
        <td>{item.orderId}</td>
        <td>
          {item.userProfile?.firstName + " " + item.userProfile?.lastName}
        </td>
        <td>{item.userProfile?.email}</td>
        <td>{item.userProfile?.phoneNumber}</td>
        <td>{item.deviceDetail?.deviceId}</td>
        <td>{item?.purchaseDate && format(item.purchaseDate, "dd/MM/yyyy")}</td>
        <td>
          {item.warrantyExpireDate &&
            format(item.warrantyExpireDate, "dd/MM/yyyy")}
        </td>
        <td>{item.noOfDaystoWarrantyExpires} day's</td>
        <td>
          {
            item?.notificationMessage[item.notificationMessage?.length - 1]
              ?.messageDescription
          }
        </td>
      </tr>
    );
  };

  const renderTableRows = () => {
    return serviceRequestList.map((item, index) => {
      return renderTableDataRow(item, index);
    });
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const allIds = serviceRequestList.map((item) => item.id);
      setSelectedItems(allIds);
      setSelectedItemData(serviceRequestList);
    } else {
      setSelectedItems([]);
      setSelectedItemData([]);
    }
  };

  const handleSelectRow = (id: number, item: any) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
      setSelectedItemData(selectedItemData.filter((item) => item.id !== id));
    } else {
      setTimeout(() => {
        setSelectedItems([...selectedItems, id]);
        setSelectedItemData([...selectedItemData, item]);
      }, 100);
    }
  };

  const fetchServiceNotification = async (noOfExpiryDays?: number) => {
    // Fetch service requests based on selected date
    const device = {
      searchText: "",
      role: "",
      currentUserId: 0,
      id: 0,
      noOfDaystoPurchased: 0,
      noOfExpiryDays: noOfExpiryDays || 0,
    } as OrderDetailSearchModel;
    const result = await searchResult(device).unwrap();
    setServiceRequestList(result);
  };

  const handleDaysToPurchased = (event: any) => {
    fetchServiceNotification(event.target.value);
  };

  const handleDaysToWarrantyExpires = (event: any) => {
    // setServiceRequestList(
    //   serviceRequestList.filter(
    //     (se) => se.noOfDaystoWarrantyExpires! >= event.target.value
    //   )
    // );
    fetchServiceNotification(event.target.value);
  };

  const resetSelectedData = () => {
    setSelectedItems([]);
    setSelectedItemData([]);
    fetchServiceNotification();
  };

  const handleSendNotification = () => {
    setSendNotification(true);
    console.log(
      "Sending notification for selected items:",
      selectedItems,
      selectedItemData
    );
  };

  const handleClose = () => {
    setSendNotification(false);
  };

  return (
    <>
      <TitleInfo
        title={"Service Notification"}
        subTitle={"Here is the list of all notification"}
      />
      <Stack
        direction="horizontal"
        gap={3}
        className="justify-content-end mb-2"
      >
        <div className="flex-fill"></div>
        <InputGroup size="sm" className="w-auto gap-3">
          {/* <Form.Select onChange={handleDaysToPurchased}>
            <option value="">--Choose Days to Purchased--</option>
            {durationFilterData?.map((df: any, index) => (
              <option key={index} value={df.value}>
                {df.label}
              </option>
            ))}
          </Form.Select> */}
          <Form.Select onChange={handleDaysToWarrantyExpires}>
            <option value="">-- Choose Days to Warranty Expires --</option>
            {durationFilterData?.map((df: any, index) => (
              <option key={index} value={df.value}>
                {df.label}
              </option>
            ))}
          </Form.Select>
        </InputGroup>
        <Button
          variant="primary"
          className="btn-sm"
          onClick={handleSendNotification}
          disabled={!selectedItemData?.length}
        >
          Send Notification
        </Button>
      </Stack>
      {sendNotification && (
        <SendNotification
          handleClose={handleClose}
          isOpenPopup={sendNotification}
          selectedItemData={selectedItemData}
          resetSelectedData={resetSelectedData}
        />
      )}
      <Table responsive hover size="sm" className="custom-table">
        {renderHeader()}
        <tbody>{renderTableRows()}</tbody>
      </Table>
    </>
  );
}

export default ServiceNotificationList;
