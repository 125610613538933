import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterValue } from "../../components/common/model";
import { RootState } from "../store";

const initialState: MasterValue[] = [];

export const lovSlice = createSlice({
  name: "lovs",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLov: (state, action: PayloadAction<MasterValue[]>) => {
      // Check for duplicates before adding to state
      const newValues = action.payload.filter((newValue) => {
        return !state.some((existingValue) => existingValue.id === newValue.id);
      });
      state.push(...newValues);
    }
  },
});

export const { setLov } = lovSlice.actions;

export const selectLov = (state: RootState) => state.lovs;

export default lovSlice.reducer;
