import { Dropdown, DropdownButton } from "react-bootstrap";

function CustomDropdown(props: {
  title: string;
  options: any[];
  onSelect: (value: any) => void;
}) {
  const { title, options, onSelect } = props;

  return (
    <DropdownButton
      size="sm"
      id="custom-dropdown"
      title={title || "Select an Option"}
    >
      {options.map((option: any, index: number) => (
        <Dropdown.Item
          key={index}
          eventKey={option}
          onClick={() => onSelect(option)}
        >
          {option}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
}

export default CustomDropdown;
