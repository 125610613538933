import { Tab, Tabs } from "react-bootstrap";
import TitleInfo from "../common/TitleInfo";
import ChangePassword from "./change-password";

function Settings() {
  return (
    <>
      <TitleInfo
        title={"Settings"}
        subTitle={"Here is the details of settings"}
      />

      <Tabs
        defaultActiveKey="change-password"
        id="justify-tab-example"
        className="mb-3"
      >
        <Tab eventKey="change-password" title="Change Password">
          {ChangePassword()}
        </Tab>
      </Tabs>
    </>
  );
}

export default Settings;
