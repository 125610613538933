import React, { useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  ForgotPasswordRequest,
  useSendEmailForOTPMutation,
  useVerifyOTPMutation,
  VerifyCode,
} from "../../redux/apis/forgotPasswordApi";
import { useAppDispatch } from "../../redux/hooks/storeHooks";
import {
  selectAccount,
  setResetPasswordUser,
} from "../../redux/slices/accountSlice";
import { FormEventType, getRecoverEmail } from "../common/helper/helper";
import "../login/Login.scss";

const VerifyOtp: React.FC = () => {
  const { email } = useParams<{ email: string }>();
  const account = useSelector(selectAccount);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const [otp, setOtp] = useState<{ [key: string]: string }>({
    one: "",
    two: "",
    three: "",
    four: "",
  });
  const [verificationResult, setVerificationResult] = useState<string | null>(
    null
  );
  const [verifyotp] = useVerifyOTPMutation();
  const [sendEmailFn, { isLoading }] = useSendEmailForOTPMutation();

  const handleChange = (key: string, value: string, index: number) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      setOtp((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  const handleSubmit = async (e: FormEventType) => {
    e.preventDefault();

    const completeOtp = Object.values(otp).join("");

    const isOtpValid = completeOtp === "1234";

    const data = {
      email: account.resetUserInfo?.email || getRecoverEmail(),
      code: completeOtp,
      codeType: "ResetPassword",
    } as VerifyCode;

    try {
      const result = await verifyotp(data).unwrap();
      dispatch(
        setResetPasswordUser({
          email: data.email,
          code: data.code,
          codeType: "ResetPassword",
        })
      );
      setVerificationResult(
        "Verification successful! Redirecting to login page..."
      );
      navigate(`/account/reset-password`);
    } catch (error) {
      setVerificationResult("Verification failed. Please try again.");
    }
  };

  const resendOtp = async () => {
    const forgetPassword = {
      email: account.resetUserInfo?.email || getRecoverEmail(),
    } as ForgotPasswordRequest;

    try {
      const result = await sendEmailFn(forgetPassword).unwrap();
      dispatch(setResetPasswordUser({ email: forgetPassword.email }));
    } catch (error) {}
  };

  return (
    <div className="auth-page">
      <form onSubmit={handleSubmit}>
        <Stack gap={4} className="col-8 col-md-3 mx-auto">
          <div className="auth-title">Verification Code</div>
          <div className="text-center text-light">
            OTP has been sent to your email. Please verify
          </div>
          <Stack direction="horizontal" gap={4}>
            {Object.entries(otp).map(([key, value], index) => (
              <input
                key={key}
                type="text"
                className="form-control form-control-lg text-center"
                value={value}
                onChange={(e) => handleChange(key, e.target.value, index)}
                maxLength={1}
                placeholder={""}
                autoComplete="off"
                autoFocus={index == 0}
              />
            ))}
          </Stack>
          <Button variant="primary" size="lg" type="submit">
            Verify OTP
          </Button>
          <Button
            variant="outline-primary"
            size="lg"
            type="button"
            onClick={resendOtp}
          >
            Reset OTP
          </Button>

          {verificationResult && (
            <div
              className={`alert ${
                verificationResult.includes("successful")
                  ? "alert-success"
                  : "alert-danger"
              } mt-3`}
              role="alert"
            >
              {verificationResult}
            </div>
          )}
        </Stack>
      </form>
    </div>
  );
};

export default VerifyOtp;
