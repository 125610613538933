import { MasterValue } from "../../components/common/model";
import { apiSlice } from "../slices/apiSlice";
import { fetchWithInterceptor } from "../utils/fetchWithInterceptor";

const BASE_URL = "/api/";

export const getLovs = async (): Promise<MasterValue[]> => {
  const routeUrl = "/master-values";
  const response = await fetchWithInterceptor(routeUrl);
  return response;
};

export const getLovByListName = async (listNames: string): Promise<any> => {
  const response = await fetch(`${BASE_URL}/master-values/list/${listNames}`, {
    method: "GET",
  });
  if (!response.ok) {
    throw new Error("Failed to get lov");
  }
  return response.json();
};

const commonConstants = {
  lovUrl: 'master-values',
}

export const lovApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLovs: builder.query<MasterValue[], void>({
          query() {
              return {
                  url: `/${commonConstants.lovUrl}`,
              };
          },
          providesTags: (result) =>
              result ? [{ ...result, type: "Summary", id: "LIST" }] : [{ type: "Summary", id: "LIST" }],
      })
  })
});

export const {
  useGetLovsQuery
} = lovApiSlice;